import { sync } from 'api/digitalAnalytics';

const getUtmParams = (url) => {
  const utmParams = [];
  const regex = /([?&]utm_[^=]+)=([^&]*)/g;
  let match = regex.exec(url);
  while (match !== null) {
    utmParams.push({
      name: match[1].substring(1),
      value: decodeURIComponent(match[2]),
    });
    match = regex.exec(url);
  }
  return utmParams;
};

const logAmplitudeEvent = async (utmValues) => {
  try {
    const digitalAnalyticsPropsUtm = {
      event: {
        name: 'Utm codes are captured',
        properties: utmValues,
      },
    };
    sync(digitalAnalyticsPropsUtm);
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

const utmTracking = (href) => {
  const utmValues = getUtmParams(href);
  if (utmValues.length) {
    logAmplitudeEvent(utmValues);
  }
};

export default utmTracking;
