import Api from 'api';
import { delay } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import format from 'date-fns/format';
import leadSourceTypes from 'constants/leadSourceTypes';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';

// action types
export const SET_ACTIVE_STEP = 'ri/get-quote/SET_ACTIVE_STEP';
export const SET_ACTIVE_STEPPER = 'ri/get-quote/SET_ACTIVE_STEPPER';
export const SET_ACTIVE_STEP_POLICY = 'ri/get-quote/SET_ACTIVE_STEP_POLICY';
export const SET_ZIP = 'ri/get-quote/SET_ZIP';
export const SET_PROPERTY = 'ri/get-quote/SET_PROPERTY';
export const SET_PRODUCTS = 'ri/get-quote/SET_PRODUCTS';
export const SET_START_DATE = 'ri/get-quote/SET_START_DATE';
export const SET_EMPLOYEE = 'ri/get-quote/SET_EMPLOYEE';
export const SET_USER = 'ri/get-quote/SET_USER';
export const SET_LEASE = 'ri/get-quote/SET_LEASE';
export const SET_APPLICANT_ID = 'ri/get-quote/SET_APPLICANT_ID';
export const SET_APPLICATION_ID = 'ri/get-quote/SET_APPLICATION_ID';
export const SET_LEAD_SOURCE_TYPE = 'ri/get-quote/SET_LEAD_SOURCE_TYPE';
export const SET_TRANSFER_POLICY_DATA = 'ri/get-quote/SET_TRANSFER_POLICY_DATA';
export const SET_ORIGINAL_INSURANCE_POLICY_ENDDATE = 'ri/get-quote/SET_ORIGINAL_INSURANCE_POLICY_ENDDATE';
export const LOAD_PROPERTY_FROM_CA = 'ri/get-quote/LOAD_PROPERTY_FROM_CA';
export const SET_INSUREDS = 'ri/get-quote/SET_INSUREDS';
export const SET_STATE_AT_STEP = 'ri/get-quote/SET_STATE_AT_STEP';
export const SET_STATE_AT_STEP_POLICY = 'ri/get-quote/SET_STATE_AT_STEP_POLICY';
export const SET_IGNORED_AT_STEP = 'ri/get-quote/SET_IGNORED_AT_STEP';
export const RESET_STEPS = 'ri/get-quote/RESET_STEPS';
const REQUEST_EMAIL_ALREADY_EXIST = 'ri/get-quote/REQUEST_EMAIL_ALREADY_EXIST';
export const SET_CURRENT_STEP_ERROR = 'ri/get-quote/SET_CURRENT_STEP_ERROR';
export const SET_CLIENT = 'ri/get-quote/SET_CLIENT';
export const SET_CUSTOMER = 'ri/get-quote/SET_CUSTOMER';

// helpers
export const getFirstInvalidStep = ({ completed, ignored }) => {
  let firstInvalidStep = completed.findIndex((stepComplete, i) => {
    return !ignored[i] && !stepComplete;
  });
  firstInvalidStep = firstInvalidStep > -1 ? firstInvalidStep : completed.length;
  return firstInvalidStep;
};
export const getFirstInvalidStepPolicy = ({ completed, ignored }) => {
  let firstInvalidStep = completed.findIndex((stepComplete, i) => {
    return !ignored[i] && !stepComplete;
  });
  firstInvalidStep = firstInvalidStep > -1 ? firstInvalidStep : completed.length;
  return firstInvalidStep;
};

export const skipIgnoredSteps = ({ proposed, ignored }) => {
  let next = ignored.findIndex((isIgnored, i) => i >= proposed && !isIgnored);
  next = next > -1 ? next : 0;
  return next;
};

export const skipIgnoredStepsPolicy = ({ proposed, ignored }) => {
  let next = ignored.findIndex((isIgnored, i) => i >= proposed && !isIgnored);
  next = next > -1 ? next : 0;
  return next;
};

export const getNextDirection = ({ activeStep, direction }, nextActive) => {
  if (activeStep === nextActive) {
    return direction;
  }

  return nextActive > activeStep ? 'forward' : 'reverse';
};

const getLeadSourceTypeId = () => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    if (params.get('leadSourceTypeId')) {
      return params.get('leadSourceTypeId');
    }
  }
  return leadSourceTypes.PHONE;
};

// reducer
const initialGetQuoteState = {
  activeStep: 0,
  activeStepper: 0,
  user: 64,
  employee: { name: '', id: null, email: null },
  leadSourceType: getLeadSourceTypeId(),
  completed: [false, false, false, false, false],
  stepAccessible: true,
  direction: 'forward',
  firstInvalidStep: 0,
  ignored: [false, false, false, false, true],
  ignoredForPolicy: [false, false, false, false, true],
  property: '',
  products: [],
  startDate: format(new Date(), 'YYYY-MM-DDTHH:mm:ssZ'),
  insureds: 0,
  zip: '',
  currentStepError: '',
  currentStepErrorDescription: '',
  originalInsurancePolicyId: null,
  originalAccountId: null,
  originalInsurancePolicyEndDate: null,
  cancelReasonTypeId: null,
};

export default (state = initialGetQuoteState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: skipIgnoredSteps({
          proposed: payload.activeStep,
          ignored: state.ignored,
        }),
        direction: getNextDirection(state, payload.activeStep),
      };
    case SET_ACTIVE_STEPPER:
      return {
        ...state,
        activeStepper: skipIgnoredSteps({
          proposed: payload.activeStepper,
          ignored: state.ignored,
        }),
        direction: getNextDirection(state, payload.activeStepper),
      };
    case SET_ACTIVE_STEP_POLICY:
      return {
        ...state,
        activeStep: skipIgnoredStepsPolicy({
          proposed: payload.activeStep,
          ignored: state.ignoredForPolicy,
        }),
        direction: getNextDirection(state, payload.activeStep),
      };
    case SET_ZIP:
      return {
        ...state,
        zip: payload.value,
        stepError: '',
        currentStepError: '',
        currentStepErrorDescription: '',
      };
    case SET_PROPERTY:
      return {
        ...state,
        property: payload.value,
        currentStepError: '',
        currentStepErrorDescription: '',
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: payload.value,
        currentStepError: '',
        currentStepErrorDescription: '',
      };
    case SET_INSUREDS:
      return {
        ...state,
        insureds: payload.value,
      };
    case SET_START_DATE:
      return {
        ...state,
        startDate: payload.value,
        currentStepError: '',
        currentStepErrorDescription: '',
      };
    case SET_CURRENT_STEP_ERROR:
      return {
        ...state,
        currentStepError: payload.currentStepError,
        currentStepErrorDescription: payload.currentStepErrorDescription,
      };
    case SET_USER:
      return {
        ...state,
        user: payload.value,
      };
    case SET_LEASE:
      return {
        ...state,
        leaseId: payload.value,
      };
    case SET_APPLICATION_ID:
      return {
        ...state,
        applicationId: payload.value,
      };
    case SET_APPLICANT_ID:
      return {
        ...state,
        applicantId: payload.value,
      };
    case SET_CLIENT:
      return {
        ...state,
        clientId: payload.value,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customerId: payload.value,
      };
    case SET_EMPLOYEE:
      return {
        ...state,
        employee: payload.value,
      };
    case SET_LEAD_SOURCE_TYPE:
      return {
        ...state,
        leadSourceType: payload.value,
      };
    case SET_ORIGINAL_INSURANCE_POLICY_ENDDATE: {
      return {
        ...state,
        originalInsurancePolicyEndDate: payload.value,
      };
    }
    case SET_TRANSFER_POLICY_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_STATE_AT_STEP: {
      const stateUpdate = {
        ...state,
        ...(payload.complete !== undefined && {
          completed: state.completed.map((stepComplete, i) => (i === payload.step ? payload.complete : stepComplete)),
        }),
        ...(payload.ignore !== undefined && {
          ignored: state.ignored.map((stepIgnored, i) => (i === payload.step ? payload.ignore : stepIgnored)),
        }),
        ...(payload.stepAccessible !== undefined && {
          stepAccessible: payload.stepAccessible ? true : payload.stepAccessible,
        }),
      };
      return {
        ...stateUpdate,
        firstInvalidStep: getFirstInvalidStep(stateUpdate),
      };
    }
    case SET_STATE_AT_STEP_POLICY: {
      const stateUpdate = {
        ...state,
        ...(payload.complete !== undefined && {
          completed: state.completed.map((stepComplete, i) => (i === payload.step ? payload.complete : stepComplete)),
        }),
        ...(payload.ignore !== undefined && {
          ignoredForPolicy: state.ignoredForPolicy.map((stepIgnored, i) =>
            i === payload.step ? payload.ignore : stepIgnored,
          ),
        }),
      };
      return {
        ...stateUpdate,
        firstInvalidStep: getFirstInvalidStepPolicy(stateUpdate),
      };
    }
    case RESET_STEPS:
      return {
        ...initialGetQuoteState,
        employee: state.employee,
      };

    default:
      return state;
  }
};

// action creators
export const setActiveStep = (payload) => ({
  type: SET_ACTIVE_STEP,
  payload,
});

export const setActiveStepper = (payload) => ({
  type: SET_ACTIVE_STEPPER,
  payload,
});

export const setActiveStepPolicy = (payload) => ({
  type: SET_ACTIVE_STEP_POLICY,
  payload,
});

export const setZip = (payload) => ({
  type: SET_ZIP,
  payload,
});

export const setProperty = (payload) => ({
  type: SET_PROPERTY,
  payload,
});

export const setProducts = (payload) => ({
  type: SET_PRODUCTS,
  payload,
});

export const setStartDate = (payload) => ({
  type: SET_START_DATE,
  payload,
});

export const setInsureds = (payload) => ({
  type: SET_INSUREDS,
  payload,
});

export const setStateAtStep = (payload) => ({
  type: SET_STATE_AT_STEP,
  payload,
});

export const setStateAtStepPolicy = (payload) => ({
  type: SET_STATE_AT_STEP_POLICY,
  payload,
});

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setLease = (payload) => ({
  type: SET_LEASE,
  payload,
});

export const setApplicant = (payload) => ({
  type: SET_APPLICANT_ID,
  payload,
});

export const setApplication = (payload) => ({
  type: SET_APPLICATION_ID,
  payload,
});

export const setClient = (payload) => ({
  type: SET_CLIENT,
  payload,
});

export const setCustomer = (payload) => ({
  type: SET_CUSTOMER,
  payload,
});

export const setEmployee = (payload) => ({
  type: SET_EMPLOYEE,
  payload,
});

export const setLeadSourceType = (payload) => ({
  type: SET_LEAD_SOURCE_TYPE,
  payload,
});

export const resetGetQuote = (payload) => ({
  type: RESET_STEPS,
  payload,
});

export const requestEmailAlreadyExist = (payload) => ({
  type: REQUEST_EMAIL_ALREADY_EXIST,
  payload,
});

export const setTransferPolicyData = (payload) => ({
  type: SET_TRANSFER_POLICY_DATA,
  payload,
});

export const setOriginalInsurancePolicyEndDate = (payload) => ({
  type: SET_ORIGINAL_INSURANCE_POLICY_ENDDATE,
  payload,
});

// selectors
export const getQuoteSelector = ({ getQuote }) => ({
  property: getQuote.property,
  products: getQuote.products,
  startDate: getQuote.startDate,
  insureds: getQuote.insureds,
  zip: getQuote.zip,
  user: getQuote.user,
  employee: getQuote.employee,
  leadSourceType: getQuote.leadSourceType,
  currentStepError: getQuote.currentStepError,
  currentStepErrorDescription: getQuote.currentStepErrorDescription,
});

// sagas
function* onSetProperty({ payload }) {
  if (payload.value) {
    const { selectedProperty } = yield select(({ properties, getQuote: { completed, ignored } }) => ({
      selectedProperty: properties[payload.value],
      isProductsComplete: completed[2],
      isInsuredsIgnored: ignored[5],
    }));
    // Check products available at property with overall availableProducts
    // let availableAtProperty = selectedProperty.availableProducts.filter(
    // )

    /**
     * If there is only one product associated with this property, select it and ignore that products step.
     */

    yield put(setStateAtStep({ step: 2, ignore: false }));

    /**
     * If the selected property having carrier id QBE selected then additional insureds, don't ignore the additional insureds step
     */
    if (selectedProperty && parseInt(selectedProperty.carrierId, 10) === 75) {
      yield put(setStateAtStep({ step: 5, ignore: false }));
    } else {
      yield put(setStateAtStep({ step: 5, ignore: true }));
    }
  }
}

// function* onSetProduct({ payload }) {
//   const carrierId = yield select(
//     ({ getQuote: { property }, properties }) =>
//       (property ? properties[property].carrierId : 0)
//   )

//   if (payload.value.length) {
//     /**
//      * If the selected property require additional insureds, don't ignore the additional insureds step
//      */
//     if (payload.value.includes('1') && parseInt(carrierId) === 75) {
//       yield put(setStateAtStep({ step: 5, ignore: false }))
//     } else {
//       yield put(setStateAtStep({ step: 5, ignore: true }))
//     }
//   }
// }

function* onRequestEmailAlreadyExist({ payload }) {
  yield put(setGlobalLoading(`Verifying Email Address...`));
  const result = yield call(Api.requestEmailAlreadyExist, payload.email);

  try {
    if (result.success === true) {
      yield delay(2000);
      yield put(unsetGlobalLoading());
      yield payload && Object.hasOwn(payload, 'onComplete') && payload.onComplete();
    } else {
      yield yield put({
        type: SET_CURRENT_STEP_ERROR,
        payload: result.message,
      });
      yield put(unsetGlobalLoading());
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(setGlobalLoading({ message: err.message, error: true }));
    yield delay(3000);
    yield put(unsetGlobalLoading());
  }
}

function* watchSetProperty() {
  yield takeLatest(SET_PROPERTY, onSetProperty);
}

// function* watchSetProduct() {
//   yield takeLatest(SET_PRODUCTS, onSetProduct)
// }

function* watchGetEmailAlreadyExist() {
  yield takeLatest(REQUEST_EMAIL_ALREADY_EXIST, onRequestEmailAlreadyExist);
}

export const sagas = [
  watchSetProperty,
  // watchSetProduct,
  watchGetEmailAlreadyExist,
];
