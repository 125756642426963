/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

const PageLoading = ({ children = 'Loading a page...', timedOut }) => (
  <div>
    {children}
    {timedOut && 'The page timed out'}
  </div>
)

PageLoading.propTypes = {
  children: PropTypes.node,
  timedOut: PropTypes.bool
}

export default PageLoading
