/* eslint-disable */
// import axios from 'axios'
import Api from 'api';
import { delay } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import {
  setActiveStep,
  setStateAtStep,
  setProperty,
  setProducts,
  SET_CURRENT_STEP_ERROR,
  setActiveStepper,
} from './getQuote';
import { validateToken } from './user';
import { QUOTE_VERSION_V2 } from 'constants/common';
import { getQuoteVersionVariant } from 'api/digitalAnalytics';

// action types
export const RECEIVE_LOCATION_DATA = 'ri/properties/RECEIVE_LOCATION_DATA';
export const REQUEST_PROPERTIES = 'ri/properties/REQUEST_PROPERTIES';
export const REQUEST_PROPERTY = 'ri/properties/REQUEST_PROPERTY';
export const CLEAR_PROPERTIES = 'ri/properties/CLEAR_PROPERTIES';

// reducer
export default (state = {}, { type, payload }) => {
  switch (type) {
    case RECEIVE_LOCATION_DATA:
      return payload.properties;
    case CLEAR_PROPERTIES:
      return (state = {});
    default:
      return state;
  }
};

// action creators
export const requestProperties = (payload) => ({
  type: REQUEST_PROPERTIES,
  payload,
});

// API call
// const getProperties = async data => {
//   try {
//     return await axios.post(`enroll/get_properties`, data)
//   } catch (error) {
//     return error
//   }
// }

// sagas
function* getPropertiesByZip({ payload }) {
  let current = yield select(
    ({
      quote: { location },
      getQuote: {
        activeStep,
        completed,
        ignored,
        stepAccessible,
        user,
        employee,
        leadSourceType,
        clientId,
        property,
        customerId,
        leaseId,
      },
    }) => ({
      activeStep,
      zip: location.postalCode,
      completed,
      ignored,
      stepAccessible,
      user,
      employee,
      leadSourceType,
      clientId,
      property,
      customerId,
      leaseId,
    }),
  );

  let quoteVersion = typeof window !== 'undefined' ? window.quoteVersion : QUOTE_VERSION_V2;
  if (!quoteVersion) {
    quoteVersion = yield getQuoteVersionVariant();
  }

  if (!current.stepAccessible) {
    yield put(setStateAtStep({ step: 1, complete: true, ignore: false }));
    yield put(setActiveStep({ activeStep: 2 }));
    yield put(setActiveStepper({ activeStepper: 2 }));
    yield payload.onComplete && payload.onComplete(quoteVersion);
  } else if (payload.zip !== current.zip) {
    yield put(setGlobalLoading(`Looking up your area.`));
    yield payload.delay && delay(payload.delay / 2);

    try {
      let list = yield call(Api.getProperties, {
        postalCode: payload.zip,
        userId: current.user,
        employeeId: current.employee.id,
        leadSourceTypeId: current.leadSourceType,
        cid: current.clientId,
        customerId: current.customerId,
        leaseId: current.leaseId,
        propertyId: payload?.property || null,
      });

      if (list.responseCode === 417) {
        yield put({
          type: SET_CURRENT_STEP_ERROR,
          payload: {
            currentStepErrorDescription: list?.result?.detailMessage,
            currentStepError: list.message,
          },
        });
        typeof payload.onRequestFail === 'function' && payload.onRequestFail();
      } else if (list.responseCode === 203) {
        yield put(validateToken({ isValidToken: false }));
      } else {
        let { properties, isFlState } = list.result;
        if (isFlState) {
          quoteVersion = QUOTE_VERSION_V2;
          if (typeof window !== 'undefined') {
            window.quoteVersion = quoteVersion;
          }
        }

        let propertyList = Object.values(properties);
        if (propertyList.length == 0) {
          yield put({
            type: SET_CURRENT_STEP_ERROR,
            payload: 'No Property found for entered zip',
          });
        } else {
          yield put({
            type: RECEIVE_LOCATION_DATA,
            payload: {
              properties: properties.reduce((acc, prop) => ({ [prop.propertyId]: prop, ...acc }), {}),
            },
          });

          let nextStep = 1;
          let autoSelectProperty = payload.property
            ? properties[payload.property]
            : propertyList.length == 1 && propertyList[0];

          if (autoSelectProperty) {
            const propertyId = autoSelectProperty?.id || autoSelectProperty?.propertyId;

            yield put(setProperty({ value: propertyId }));
            yield put(setStateAtStep({ step: 1, complete: true, ignore: false }));
            nextStep = 2;
            yield put(setActiveStepper({ activeStepper: 1 }));
          } else {
            // reset property step
            yield put(setProperty({ value: '' }));
            yield current.completed[1] && put(setStateAtStep({ step: 1, complete: false, ignore: false }));
            yield put(setActiveStepper({ activeStepper: 1 }));
          }

          // let availableProductKeys = Object.keys(availableProducts)

          let autoSelectProducts = autoSelectProperty
            ? [
                ...(autoSelectProperty.insuranceCarrierId > 0 && ['1']),
                // ...(autoSelectProperty.availableProducts.includes('5') &&
                //   autoSelectProperty.idTheftAutoEnabled == true && ['5']),
              ]
            : // : availableProductKeys.length == 1
              // ? availableProductKeys
              [];

          if (autoSelectProducts.length > 1) {
            yield put(setProducts({ value: autoSelectProducts }));
            yield put(setStateAtStep({ step: 2, complete: true, ignore: true }));

            if (nextStep == 2) {
              nextStep == 3;
            }
          } else {
            // reset products step
            yield put(setProducts({ value: [] }));
            yield current.completed[2] && put(setStateAtStep({ step: 2, complete: false, ignore: false }));
          }

          yield put(setActiveStep({ activeStep: nextStep }));

          yield payload.delay && delay(payload.delay / 2);
          yield payload.onComplete && payload.onComplete(quoteVersion);
        }
      }
    } catch (err) {
      if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
      yield put(setGlobalLoading({ message: err.message, error: true }));
    }
    yield delay(1000);
    yield put(unsetGlobalLoading());
  } else {
    // Find first step that is after step 1 and not ignored
    let next = current.ignored.findIndex((ignore, i) => i > 0 && !ignore);

    next = next > -1 ? next : 1;

    yield put(setActiveStep({ activeStep: next }));
    yield payload.onComplete && payload.onComplete();
  }
}

function* watchGetProperties() {
  yield takeLatest(REQUEST_PROPERTIES, getPropertiesByZip);
}

export const sagas = [watchGetProperties];
