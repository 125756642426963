import { all } from 'redux-saga/effects';
import { sagas as getQuoteSagas } from './modules/getQuote';
import { sagas as paymentsSagas } from './modules/payments';
import { sagas as paymentMethodsSagas } from './modules/paymentMethods';
import { sagas as paymentTypeSagas } from './modules/paymentTypes';
import { sagas as policySagas } from './modules/policies';
import { sagas as pricinSagas } from './modules/pricing';
import { sagas as propertySagas } from './modules/properties';
import { sagas as saveQuoteSagas } from './modules/savedQuote';
import { sagas as userSagas } from './modules/user';
import { sagas as verifyAddressSagas } from './modules/verifyAddress';
import { sagas as enrollmentSagas } from './modules/enrollment';
import { sagas as notesSagas } from './modules/notes';
import { sagas as legalNotices } from './modules/legalNotices';
import { sagas as quotePackages } from './modules/quotePackages';
import { sagas as quoteSagas } from './modules/quote';
import { sagas as updateUserSagas } from './modules/updateArrayUser';

export default function* rootSaga() {
  yield all(
    [
      ...getQuoteSagas,
      ...paymentsSagas,
      ...paymentMethodsSagas,
      ...paymentTypeSagas,
      ...policySagas,
      ...pricinSagas,
      ...propertySagas,
      ...saveQuoteSagas,
      ...userSagas,
      ...verifyAddressSagas,
      ...enrollmentSagas,
      ...notesSagas,
      ...legalNotices,
      ...quotePackages,
      ...quoteSagas,
      ...updateUserSagas,
    ].map((func) => func()),
  );
}
