const paymentTypes = {
  4: {
    name: 'eCheck',
    value: 4,
  },
  6: {
    name: 'MasterCard',
    value: 6,
  },
  5: {
    name: 'Visa',
    value: 5,
  },
  7: {
    name: 'Discover',
    value: 7,
  },
  8: {
    name: 'Amex',
    value: 8,
  },
};

export default paymentTypes;
