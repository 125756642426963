/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, LoadingDots } from '@entrata/portals-common/lib/components';
import { transparentize } from 'polished';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Transition } from 'react-transition-group';

const StyledDiv = styled('div')`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.secondary.base};
  text-align: center;

  h2 {
    font-size: 32px;
    ${({ description }) =>
      description && {
        marginBottom: `10px`,
      }}
      font-family: ${({ theme }) => theme.fonts.algebraLight};
  }
`;

const StyledDescription = styled('p')`
  text-align: center;
  width: 75%;
  margin: auto;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.gray.base };
`;

const GlobalLoading = ({ message, show, description = null }) => (
  <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
    {(status) => (
      <Overlay color={transparentize(0.1, '#fff')} show={show}>
        <StyledDiv description={description}>
          {message && <h2>{message}</h2>}
          {description && <StyledDescription>{description}</StyledDescription>}
          <LoadingDots width={120} />
        </StyledDiv>
      </Overlay>
    )}
  </Transition>
);

GlobalLoading.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool.isRequired,
  description: PropTypes.string,
};

export default connect(({ globalLoading: { loading = false, message, description = null } }) => ({
  show: loading,
  message,
  description,
}))(GlobalLoading);
