const leadSourceTypes = {
  DIRECT: 1,
  PHONE: 2,
  APPLICATION: 3,
  ONLINE_LEASE: 4,
  RESIDENT_PORTAL: 5,
  RENT_EMAIL: 6,
  RI_ENROLLMENT: 7,
  MOVE_IN_RI_ENROLLMENT: 8,
  ADD_NEW_POLICY: 9,
  REINSTATE_POLICY: 10,
  UNINSURED_RESIDENT_EMAIL: 11,
  ENTRATA: 12,
  IDTHEFT_SUBSCRIPTION: 13,
  POINT_OF_LEASE: 14,
  ONLINE_LEASE_POL: 15,
  CUSTOM_POLICY_VERIFICATION_DENIED: 16,
  MP_FIRST_NOTICE: 17,
  MP_LAST_NOTICE: 18,
  MP_RENEWAL_NOTICE: 19,
  EXPIRING_SOON_NOTICE: 20,
  LEASE_COMPLETED_INSURANCE_INVITATION: 21,
  MANUAL_INVITATION: 22,
  LEASE_COMPLETED_FOLLOWUP: 23,
  MOVE_IN_POL: 24,
  EMAIL_CAMPAIGN: 25,
  TRASFER_POLICY: 30,
  HOMEBODY_APP: 31,
};

export default leadSourceTypes;
