import React from 'react';
import { Provider } from 'react-redux';
import configureStore from 'redux/configureStore';
import { ThemeProvider } from 'emotion-theming';
import '@entrata/i18n';
import AuthProvider from 'components/AuthProvider';
// import AuthRoute from 'components/AuthRoute'
import { GlobalLoading } from 'components/loaders';
import { Global, css } from '@emotion/core';
import { homeBodyTheme } from 'constants/theme';
import setGlobalStyles from './setGlobalStyles';

// REMOVE LATER
// temporary stashing of user in localStorage for dev purposes
const maxLoginTime = 86400000;

// REMOVE LATER
// use local user data if it's there's to start
function getInitialUserState() {
  if (typeof localStorage !== 'undefined') {
    const localUser = JSON.parse(localStorage.getItem('ri:loggedUser'));
    if (localUser) {
      if (new Date() - localUser.loggedIn > maxLoginTime) {
        localStorage.removeItem('ri:loggedUser');
      } else {
        return { user: localUser };
      }
    }
  }
  return {};
}

const store = configureStore(typeof window === 'undefined' ? {} : getInitialUserState());

// How to handle auth routes ??

export default function WrapRootWithProviders({ element }) {
  return (
    <>
      <Global
        styles={css`
          ${setGlobalStyles(homeBodyTheme)}
        `}
      />
      <Provider store={store}>
        <ThemeProvider theme={homeBodyTheme}>
          <AuthProvider>
            {element}
            <GlobalLoading />
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}
