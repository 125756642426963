import Api from 'api';
import { all, select, call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { USER_LOGOUT } from 'redux/modules/user';
import { CLEAR_UPCOMING_PAYMENTS, UPDATE_PAYMENT_FAILED } from 'redux/modules/payments';
import { POLICY_PAYMENT_FAILED_UPDATE } from 'redux/modules/policies';
import insuranceCarrierTypes from 'constants/insuranceCarrierTypes';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { validateToken } from './user';
import { addError } from './errors';

// action types
const ADD_PAYMENT_METHOD = 'ri/paymentMethods/ADD_PAYMENT_METHOD';
const EDIT_PAYMENT_METHOD = 'ri/paymentMethods/EDIT_PAYMENT_METHOD';
const GET_PAYMENT_AMOUNT = 'ri/paymentMethods/GET_PAYMENT_AMOUNT';
const GET_HO4_IDTHEFT_PAYMENT_AMOUNT = 'ri/paymentMethods/GET_HO4_IDTHEFT_PAYMENT_AMOUNT';
const RECEIVED_PAYMENT_AMOUNT = 'ri/paymentMethods/RECEIVED_PAYMENT_AMOUNT';
const REMOVE_PAYMENT_AMOUNT = 'ri/paymentMethods/REMOVE_PAYMENT_AMOUNT';
export const RECEIVE_PAYMENT_METHODS = 'ri/paymentMethods/RECEIVE_PAYMENT_METHODS';
const REMOVE_PAYMENT_METHOD = 'ri/paymentMethods/REMOVE_PAYMENT_METHOD';
const REQUEST_PAYMENT_METHODS = 'ri/paymentMethods/REQUEST_PAYMENT_METHODS';
const UPDATE_PAYMENT_METHOD = 'ri/paymentMethods/UPDATE_PAYMENT_METHOD';
const UPDATE_PAYMENT_PROCESS = 'ri/paymentMethods/UPDATE_PAYMENT_PROCESS';
const SET_ACCOUNT_MISSING_POLICIES = 'ri/paymentMethods/SET_ACCOUNT_MISSING_POLICIES';
const UNSET_ACCOUNT_MISSING_POLICIES = 'ri/paymentMethods/UNSET_ACCOUNT_MISSING_POLICIES';
const CLEAR_PAYMENT_METHODS = 'ri/paymentMethods/CLEAR_PAYMENT_METHODS';
const RECEIVED_PAYMENT_METHOD = 'ri/paymentMethods/RECEIVED_PAYMENT_METHOD';

const initialState = {
  methods: [],
  paymentDetails: {},
  isPaymentProcessing: false,
  accountMissingPolicies: [],
};

const getPaymentAmountByInsuranceCarrierId = (insuranceCarrierId) => {
  if (parseInt(insuranceCarrierId, 10) === insuranceCarrierTypes.IDTHEFT) {
    return Api.getIdTheftPaymentAmount;
  }
  if (parseInt(insuranceCarrierId, 10) === insuranceCarrierTypes.ARRAY) {
    return Api.getArrayIdTheftPaymentCollectionDetails;
  }
  return Api.getPaymentAmount;
};

// reducer
export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RECEIVE_PAYMENT_METHODS:
      return {
        ...state,
        methods: payload.methods,
      };
    case RECEIVED_PAYMENT_METHOD:
      return {
        ...state,
        methods: [...state.methods, { ...payload.method }],
      };
    case CLEAR_PAYMENT_METHODS:
      return {
        ...state,
        methods: [],
        accountMissingPolicies: [],
      };
    case SET_ACCOUNT_MISSING_POLICIES:
      return {
        ...state,
        accountMissingPolicies: payload,
      };
    case UNSET_ACCOUNT_MISSING_POLICIES: {
      return {
        ...state,
        accountMissingPolicies: state.accountMissingPolicies.filter(({ id }) => id !== payload.id),
      };
    }
    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        methods: [
          { ...payload.method },
          ...state.methods.filter(({ accountId }) => parseInt(accountId, 10) !== parseInt(payload.accountId, 10)),
        ],
      };
    case REMOVE_PAYMENT_METHOD:
      return Object.keys(state)
        .filter((key) => state[key].id !== payload)
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: state[key],
          }),
          {},
        );
    case RECEIVED_PAYMENT_AMOUNT:
      return {
        ...state,
        paymentDetails: {
          ...payload,
        },
      };
    case REMOVE_PAYMENT_AMOUNT:
      return {
        ...state,
        paymentDetails: {},
      };
    case UPDATE_PAYMENT_PROCESS:
      return {
        ...state,
        isPaymentProcessing: payload.isPaymentProcessing,
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

// action creators
export const receivePaymentMethods = (payload) => ({
  type: RECEIVE_PAYMENT_METHODS,
  payload,
});

export const requestPaymentMethods = (payload) => ({
  type: REQUEST_PAYMENT_METHODS,
  payload,
});

export const addPaymentMethod = (payload) => ({
  type: ADD_PAYMENT_METHOD,
  payload,
});

export const editPaymentMethod = (payload) => ({
  type: EDIT_PAYMENT_METHOD,
  payload,
});

export const updatePaymentMethod = (payload) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload,
});

export const removePaymentMethod = (payload) => ({
  type: REMOVE_PAYMENT_METHOD,
  payload,
});

export const getPaymentAmount = (payload) => ({
  type: GET_PAYMENT_AMOUNT,
  payload,
});

export const getHo4IdTheftPaymentAmount = (payload) => ({
  type: GET_HO4_IDTHEFT_PAYMENT_AMOUNT,
  payload,
});

export const removePaymentAmount = (payload) => ({
  type: REMOVE_PAYMENT_AMOUNT,
  payload,
});

export const updatePaymentProcess = (payload) => ({
  type: UPDATE_PAYMENT_PROCESS,
  payload,
});

// sagas
function* workerGetPaymentMethods({ payload }) {
  yield put(setGlobalLoading(`Loading payment methods...`));
  try {
    const { responseCode, result } = yield call(Api.getPaymentMethods, payload);
    if (responseCode === 200) {
      yield put({
        type: RECEIVE_PAYMENT_METHODS,
        payload: {
          methods: result.paymentMethods,
        },
      });
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
    if (result?.accountMissingPolicies) {
      yield put({
        type: SET_ACCOUNT_MISSING_POLICIES,
        payload: result.accountMissingPolicies,
      });
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
  yield put(unsetGlobalLoading());
}

function* workerGetPaymentAmount({ payload }) {
  try {
    const { responseCode, message, result } = yield call(
      getPaymentAmountByInsuranceCarrierId(payload.insuranceCarrierId),
      payload,
    );
    if (responseCode === 200) {
      const {
        convenienceFee,
        paymentAmount,
        totalPaymentAmount,
        autoPaymentChargeMessage,
        statePolicyFee,
        statePolicyFeeIncluded,
        statePolicyFeeMessage,
      } = result;
      yield put({
        type: RECEIVED_PAYMENT_AMOUNT,
        payload: {
          convenienceFee,
          paymentAmount,
          idTheftPaymentAmount: 0,
          totalPaymentAmount,
          autoPaymentChargeMessage,
          statePolicyFee,
          statePolicyFeeIncluded,
          statePolicyFeeMessage,
        },
      });
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        addError({
          key: 'updatePaymentMethodError',
          message,
        }),
      );
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
}

function* workerGetHo4IdTheftPaymentAmount({ payload }) {
  try {
    if ([insuranceCarrierTypes.IDTHEFT, insuranceCarrierTypes.ARRAY].includes(payload.insuranceCarrierId)) {
      const { responseCode, result, message } = yield call(
        getPaymentAmountByInsuranceCarrierId(payload.insuranceCarrierId),
        payload,
      );
      if (responseCode === 200) {
        const { convenienceFee, paymentAmount, totalPaymentAmount, autoPaymentChargeMessage } = result;

        yield put({
          type: RECEIVED_PAYMENT_AMOUNT,
          payload: {
            convenienceFee,
            paymentAmount,
            idTheftPaymentAmount: paymentAmount,
            totalPaymentAmount,
            autoPaymentChargeMessage,
            statePolicyFee: 0,
            statePolicyFeeIncluded: false,
            statePolicyFeeMessage: '',
          },
        });
      } else if (responseCode === 203) {
        yield put(validateToken({ isValidToken: false }));
      } else {
        yield put(
          addError({
            key: 'updatePaymentMethodError',
            message,
          }),
        );
      }
    } else {
      const { responseCode, result, message } = yield call(Api.getPaymentAmount, payload);
      if (responseCode === 200) {
        const {
          convenienceFee,
          paymentAmount,
          autoPaymentChargeMessage,
          statePolicyFee,
          statePolicyFeeIncluded,
          statePolicyFeeMessage,
          totalPaymentAmount,
        } = result;

        yield put({
          type: RECEIVED_PAYMENT_AMOUNT,
          payload: {
            convenienceFee,
            paymentAmount,
            idTheftPaymentAmount: 0,
            totalPaymentAmount,
            autoPaymentChargeMessage,
            statePolicyFee,
            statePolicyFeeIncluded,
            statePolicyFeeMessage,
          },
        });
      } else if (responseCode === 203) {
        yield put(validateToken({ isValidToken: false }));
      } else {
        yield put(
          addError({
            key: 'updatePaymentMethodError',
            message,
          }),
        );
      }
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
}

function* workerUpdatePaymentMethod({ payload }) {
  try {
    yield put(updatePaymentProcess({ isPaymentProcessing: true }));
    const { responseCode, result, message } = yield call(Api.updatePaymentMethod, payload);

    const paymentMethods = yield select(
      createSelector(
        (state) => state.paymentMethods,
        (payMethods) => {
          return payMethods.methods;
        },
      ),
    );

    const paymentMethod = paymentMethods.find(
      (payMethod) => parseInt(payMethod.accountId, 10) === parseInt(payload.accountId, 10),
    );

    if (responseCode === 200) {
      const {
        paymentTypeId,
        accountId,
        accountNumber,
        accountTypeId,
        bankName,
        billingAddress,
        cardNumber,
        expiration,
        insurancePolicyId,
        nameOnAccount,
        nameOnCard,
        routingNumber,
      } = result;

      let method = {
        ...paymentMethod,
        accountId,
        paymentTypeId,
        insurancePolicyId,
        billingAddress,
      };

      if (parseInt(paymentTypeId, 10) === 4) {
        method = {
          ...method,
          nameOnAccount,
          bankName,
          accountTypeId,
          routingNumber,
          accountNumber,
        };
      } else {
        method = {
          ...method,
          nameOnCard,
          cardNumber,
          expiration,
        };
      }

      yield all([
        put(
          addError({
            key: 'updatePaymentMethodSuccess',
            message,
          }),
        ),
        put(
          updatePaymentMethod({
            accountId: payload.accountId,
            method: {
              ...method,
              isPaymentFailed: 0,
            },
          }),
        ),
        put({ type: CLEAR_UPCOMING_PAYMENTS }),
        put({
          type: POLICY_PAYMENT_FAILED_UPDATE,
          payload: {
            insurancePolicyId,
            isPaymentFailed: 0,
          },
        }),
        put({
          type: UPDATE_PAYMENT_FAILED,
          payload: {
            insurancePolicyId,
            isPaymentFailed: 0,
          },
        }),
      ]);
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      if (result && Object.hasOwn(result, 'isAllowedRealtimePayment')) {
        const { isAllowedRealtimePayment, dailyMaxPaymentAttemptMessage } = result;

        yield put(
          updatePaymentMethod({
            accountId: payload.accountId,
            method: {
              ...paymentMethod,
              isAllowedRealtimePayment,
              dailyMaxPaymentAttemptMessage,
            },
          }),
        );
      }

      yield put(
        addError({
          key: 'updatePaymentMethodError',
          message,
        }),
      );
    }

    yield put(updatePaymentProcess({ isPaymentProcessing: false }));
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    yield put(
      addError({
        key: 'updatePaymentMethodError',
        message: error.toString(),
      }),
    );
    yield put(updatePaymentProcess({ isPaymentProcessing: false }));
  }
}

function* workerAddPaymentMethod({ payload }) {
  try {
    yield put(updatePaymentProcess({ isPaymentProcessing: true }));
    const { responseCode, message, result } = yield call(Api.addPaymentMethod, payload);
    if (responseCode === 200) {
      const { isHo4PaymentFailed, isIdTheftPaymentFailed } = result;
      let paymentMethod = {};
      if (result[insuranceCarrierTypes.MARKEL]) {
        paymentMethod = result[insuranceCarrierTypes.MARKEL];
      }
      if (result[insuranceCarrierTypes.IDTHEFT]) {
        paymentMethod = result[insuranceCarrierTypes.IDTHEFT];
      }
      yield all([
        put({ type: CLEAR_UPCOMING_PAYMENTS }),
        put({
          type: RECEIVED_PAYMENT_METHOD,
          payload: {
            method: paymentMethod,
          },
        }),
        put({
          type: UNSET_ACCOUNT_MISSING_POLICIES,
          payload: {
            id:
              payload.insuranceCarrierId === insuranceCarrierTypes.IDTHEFT
                ? payload.idTheftPolicyId
                : payload.insurancePolicyId,
          },
        }),
      ]);
      const isPaymentFailed = Boolean(isHo4PaymentFailed || isIdTheftPaymentFailed);
      const carrierId = isHo4PaymentFailed ? insuranceCarrierTypes.MARKEL : insuranceCarrierTypes.IDTHEFT;
      if (isPaymentFailed && carrierId === payload.insuranceCarrierId) {
        yield payload.callback(`/app/payments/edit-payment/:${result[carrierId].accountId}`);
        yield put(
          addError({
            key: 'updatePaymentMethodError',
            message: result[carrierId].message,
          }),
        );
      } else {
        yield put(
          addError({
            key: 'updatePaymentMethodSuccess',
            message,
          }),
        );
      }
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        addError({
          key: 'updatePaymentMethodError',
          message,
        }),
      );
    }
    yield put(updatePaymentProcess({ isPaymentProcessing: false }));
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    yield put(
      addError({
        key: 'updatePaymentMethodError',
        message: error.toString(),
      }),
    );
    yield put(updatePaymentProcess({ isPaymentProcessing: false }));
  }
}

function* watchGetPaymentMethods() {
  yield takeLatest(REQUEST_PAYMENT_METHODS, workerGetPaymentMethods);
}

function* watchUpdatePaymentMethod() {
  yield takeLatest(EDIT_PAYMENT_METHOD, workerUpdatePaymentMethod);
}

function* watchAddPaymentMethod() {
  yield takeLatest(ADD_PAYMENT_METHOD, workerAddPaymentMethod);
}

function* watchGetPaymentAmount() {
  yield takeLatest(GET_PAYMENT_AMOUNT, workerGetPaymentAmount);
}

function* watchGetHo4IdTheftPaymentAmount() {
  yield takeLatest(GET_HO4_IDTHEFT_PAYMENT_AMOUNT, workerGetHo4IdTheftPaymentAmount);
}

export const sagas = [
  watchGetPaymentMethods,
  watchUpdatePaymentMethod,
  watchAddPaymentMethod,
  watchGetPaymentAmount,
  watchGetHo4IdTheftPaymentAmount,
];
