/* eslint-disable */
import Api from 'api';
import { call, put, takeLatest, all } from 'redux-saga/effects';
// import { RECEIVE_POLICIES } from './policies'
import { USER_LOGOUT, validateToken } from './user';
import { addError } from 'redux/modules/errors';
import { REQUEST_POLICY_DETAILS } from 'redux/modules/policies';

// action types
export const ADD_NOTE = 'ri/notes/ADD_NOTE';
export const INSERT_NOTE = 'ri/notes/INSERT_NOTE';
const RECEIVE_NOTES = 'ri/notes/RECEIVE_NOTES';

// reducer
export default (state = {}, action) => {
  const addNote = () => {
    const note = action.payload;
    const notes = state[note.carrierPolicyNumber]
      ? state[note.carrierPolicyNumber].unshift(note)
      : { [note.carrierPolicyNumber]: [{...note}] };
    return {
      ...state,
      ...notes,
    };
  };

  const receivedNotes = () => {
    return {
      ...state,
      ...Object.groupBy(action.payload, ({carrierPolicyNumber}) => carrierPolicyNumber),
    };
  }

  switch (action.type) {
    case ADD_NOTE:
      return addNote();
    case REQUEST_POLICY_DETAILS:
    case USER_LOGOUT:
      return {};
    case RECEIVE_NOTES:
      return receivedNotes();
    default:
      return state;
  }
};

// action creators
export const addNote = payload => ({
  type: ADD_NOTE,
  payload,
});

export const insertNote = payload => ({
  type: INSERT_NOTE,
  payload,
});

export const receiveNotes = payload => ({
  type: RECEIVE_NOTES,
  payload,
});

function* insertNotesWorker({ payload }) {
  try {
    const { result, message, responseCode } = yield call(
      Api.insertNote,
      payload
    );
    if (responseCode === 200) {
      yield all([
        put(
          addError({
            key: 'addNote',
            message,
          })
        ),
        put(addNote(result)),
      ]);
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'addNoteError',
        message: err.toString(),
      })
    );
  }
}

function* watchInsertNotes() {
  yield takeLatest(INSERT_NOTE, insertNotesWorker);
}

export const sagas = [watchInsertNotes];
