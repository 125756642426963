/* eslint-disable */
import Api from 'api';
import { call, select, put, takeLatest, all } from 'redux-saga/effects';
import { CLEAR_QUOTE } from './quote';
import { createSelector } from 'reselect';
import format from 'date-fns/format';
import { delay } from 'redux-saga';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { updatePaymentInfo } from './enrollPaymentInfo';
import { validateToken, updateUser } from './user';
import { unsetLoading } from './loading';
import { addError } from './errors';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import leadSourceTypes from 'constants/leadSourceTypes';
import { pushReferrer } from './referrers';
import { WATER_DAMAGE_ENDORSEMENT, SILVER_ENHANCEMENT_ENDORSEMENT, EARTHQUAKE_ENDORSEMENT } from './quoteValues';
import { sync, events, properties as analyticsProperties } from 'api/digitalAnalytics';
import { getEndorsement, getLimitById } from './quotePackages';
import { PRODUCT_IDS } from 'constants/packages';

// action types
const REQUEST_SAVE_QUOTE = 'ri/savedQuote/REQUEST_SAVE_QUOTE';
const REQUEST_INSERT_QUOTE = 'ri/savedValues/REQUEST_INSERT_QUOTE';
export const INSERT_QUOTE = 'ri/savedQuote/INSERT_QUOTE';
export const ERROR_SAVE_QUOTE = 'ri/savedQuote/ERROR_SAVE_QUOTE';
export const SAVE_QUOTE = 'ri/savedQuote/SAVE_QUOTE';
export const UPDATE_FREQUENCY_SAVE_QUOTE = 'ri/savedQuote/UPDATE_FREQUENCY_SAVE_QUOTE';
const REDIRECTED = 'ri/savedQuote/REDIRECTED';
const RESET_SAVE_QOUTE_SUCCESS = 'ri/savedQuote/RESET_SAVE_QOUTE_SUCCESS';
const REQUEST_CREATE_POLICY_AFTER_LOGIN = 'ri/policies/REQUEST_CREATE_POLICY_AFTER_LOGIN';
const SAVE_INCOMPLETE_POLICY_DETAILS = 'ri/savedQuote/SAVE_INCOMPLETE_POLICY_DETAILS';

let initialState = {
  emailAddress: '',
  error: '',
  currentQuote: {},
  insertedQuote: {},
  isSaveQuoteSuccess: false,
};

let getQuote = (state) => state.getQuote;
let quote = (state) => state.quote;
let enrollCustomerInfo = (state) => state.enrollCustomerInfo;
let savedQuoteSelecter = (state) => state.savedQuote;
let policiesSelectoer = (state) => state.policies;
let paymentInfo = (state) => state.enrollPaymentInfo;

export const paymentInfoSelector = createSelector(paymentInfo, () => ({
  paymentInfo,
}));

export const insertQuoteSelector = createSelector(
  getQuote,
  quote,
  enrollCustomerInfo,
  savedQuoteSelecter,
  policiesSelectoer,
  (
    {
      startDate,
      user,
      employee,
      leadSourceType,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      originalInsurancePolicyId,
      originalAccountId,
    },
    {
      personalPremiumAmount,
      liabilityPremium,
      monthlyPremiumAmount,
      premiumTaxAmount,
      additionalTaxAmount,
      earthQuakePremiumAmount,
      nonHurricanePremium,
      waterDemagePremium,
      statePolicyFee,
      figaPremium,
      figaePremium,
      billingFrequency,
      idTheftEndorsementId,
      idTheftEndorsements,
      selectedProperty,
      adminFee,
      liabilityLimits,
      personalContentLimits,
      deductibleLimits,
      hurricaneDeductibleLimits,
      liabilityLimitId,
      personalContentLimitId,
      deductibleLimitId,
      hurricaneDeductibleLimitId,
      enhancementEndorsementId,
      ho4Endorsements,
      ho4EndorsementIds,
      availableProducts: { 1: ho4Product = {}, 5: idTheftProduct = {} },
    },
    {
      nameFirst,
      nameLast,
      emailAddress,
      password,
      phoneNumber,
      streetLine,
      mailingCity,
      mailingPostalCode,
      mailingState,
      mailingStreetLine,
      ssnData,
    },
    { currentQuote, insertedQuote },
    { isInsuredUpdated },
  ) => {
    // Get the idTheftEndorsement from the idTheftEndorsements array
    const idTheftEndorsement = idTheftEndorsementId ? getEndorsement(idTheftEndorsements, idTheftEndorsementId) : null;

    // Get the silver endorsement
    const silverEndorsement = ho4Endorsements.find(
      ({ endorsementTypeId }) => endorsementTypeId == SILVER_ENHANCEMENT_ENDORSEMENT,
    );

    // Add enhancementEndorsementId to ho4EndorsementIds if it is not already in the array
    enhancementEndorsementId && ho4EndorsementIds.push(enhancementEndorsementId);

    // Get the selected endorsement details from the ho4Endorsements array
    let endorsements = ho4EndorsementIds.reduce((acc, endorsementId) => {
      const endorsement = getEndorsement(ho4Endorsements, endorsementId);
      if (endorsement && endorsement.id != silverEndorsement?.id && endorsement.showOnWebsite) {
        let endorsementPremiumAmount = endorsement.premiumAmount || 0;
        if (endorsement['endorsementTypeId'] == EARTHQUAKE_ENDORSEMENT) {
          endorsementPremiumAmount = earthQuakePremiumAmount;
        }

        if (endorsement['endorsementTypeId'] == WATER_DAMAGE_ENDORSEMENT) {
          endorsementPremiumAmount = waterDemagePremium;
        }

        return {
          ...acc,
          [`${endorsementId}`]: {
            id: endorsement.id,
            productId: PRODUCT_IDS.HO4,
            name: endorsement.name,
            premiumAmount: parseFloat(endorsementPremiumAmount),
            showOnWebsite: endorsement.showOnWebsite,
          },
        };
      } else {
        return acc;
      }
    }, {});

    const { idTheftPropertyFee = 0 } = idTheftProduct;
    // Add the idTheftEndorsement to the endorsements object if it is selected
    if (idTheftEndorsementId) {
      endorsements = {
        ...endorsements,
        [`${idTheftEndorsement.id}`]: {
          id: idTheftEndorsement.id,
          productId: PRODUCT_IDS.IdTheft,
          name: idTheftEndorsement.name,
          premiumAmount: parseFloat(idTheftEndorsement.premiumAmount) - parseFloat(idTheftPropertyFee) || 0,
          showOnWebsite: idTheftEndorsement.showOnWebsite,
          endorsementTypeId: idTheftEndorsement.endorsementTypeId,
        },
      };
    }

    // Add the additionalTaxAmount as endorsement to the endorsements object if it not null
    if (parseFloat(additionalTaxAmount) > 0) {
      endorsements = {
        ...endorsements,
        0: {
          id: '0',
          productId: PRODUCT_IDS.HO4,
          name: 'NULL',
          premiumAmount: parseFloat(additionalTaxAmount),
        },
      };
    }

    return {
      userId: parseInt(user) || null,
      employeeId: parseInt(employee.id) || null,
      cid: parseInt(selectedProperty.clientId) || null,
      customerId: parseInt(customerId) || null,
      leaseId: parseInt(leaseId) || null,
      propertyId: parseInt(selectedProperty.propertyId) || null,
      applicantId: parseInt(applicantId) || null,
      applicationId: parseInt(applicationId) || null,
      leadSourceTypeId: parseInt(leadSourceType) || null,
      originalInsurancePolicyId: parseInt(originalInsurancePolicyId) || null,
      originalAccountId: parseInt(originalAccountId) || null,
      isIdtheftSelected: !!idTheftEndorsementId && leadSourceType != leadSourceTypes.TRASFER_POLICY,
      stateCode: selectedProperty.stateCode,
      insurancePolicyId: parseInt(insertedQuote.insurancePolicyId) || null,
      quoteId: parseInt(currentQuote.policyQuoteId) || null,
      quoteHistoryId: parseInt(insertedQuote.quoteHistoryId) || null,
      carrierClassificationTypeId: parseInt(selectedProperty.carrierClassificationTypeId) || null,
      insuranceCarrierId: selectedProperty?.insuranceCarrierId || selectedProperty?.carrierId,
      policyFrequencyId: billingFrequency,
      effectiveDate: format(startDate, 'MM/DD/YYYY'),
      premiumAmount: parseFloat(monthlyPremiumAmount) || 0,
      adminFee: parseFloat(adminFee) || 0,
      personalPremium: parseFloat(personalPremiumAmount) || 0,
      liabilityPremium: parseFloat(liabilityPremium) || 0,
      premiumTaxAmount: parseFloat(premiumTaxAmount) || 0,
      idTheftPremiumAmount: parseFloat(idTheftEndorsement?.premiumAmount) || 0,
      idTheftAdminFee: parseFloat(idTheftEndorsement?.adminFee) || 0,
      idTheftPropertyFee: !!idTheftEndorsementId && idTheftProduct ? idTheftPropertyFee : 0,
      isInsuredUpdated: isInsuredUpdated,
      liabilityLimits: getLimitById(liabilityLimits, liabilityLimitId),
      personalLimits: getLimitById(personalContentLimits, personalContentLimitId),
      deductibleLimit: getLimitById(deductibleLimits, deductibleLimitId),
      medicalPaymentLimitId: {
        id: ho4Product.medicalPaymentLimitId,
      },
      enhancementEndorsementId: enhancementEndorsementId,
      customerDetails: {
        nameFirst: nameFirst,
        nameLast: nameLast,
        emailAddress: emailAddress,
        phoneNumber: formatPhoneNumber(phoneNumber),
        password: password,
        streetAddress: streetLine,
        city: selectedProperty.city,
        stateCode: selectedProperty.stateCode,
        postalCode: selectedProperty.postalCode,
        mailingStreetAddress: mailingStreetLine,
        mailingState: mailingState,
        mailingCity: mailingCity,
        mailingPostalCode: mailingPostalCode,
        ssnData: ssnData,
      },
      windstormConstructionCreditValue: ho4Product.windstormConstructionCreditValue,
      buildingCodeGradingValue: ho4Product.buildingCodeGradingValue,
      automaticSprinklerCreditValue: ho4Product.automaticSprinklerCreditValue,
      statePolicyFee: parseFloat(statePolicyFee),
      figaPremium: parseFloat(figaPremium),
      figaePremium: parseFloat(figaePremium),
      nonHurricanePremium: nonHurricanePremium,
      endorsements: endorsements,
      hurricaneDeductibleLimit:
        typeof hurricaneDeductibleLimits != 'undefined' && hurricaneDeductibleLimits.length > 0
          ? getLimitById(hurricaneDeductibleLimits, hurricaneDeductibleLimitId)
          : {},
    };
  },
);

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FREQUENCY_SAVE_QUOTE:
      return {
        ...state,
        insertedQuote: {
          ...state.insertedQuote,
          policyFrequencyId: action.payload.policyFrequencyId,
        },
      };

    case SAVE_QUOTE:
      var savedQuoteData = {
        ...state,
        ...(action.payload.isSaveQuoteSuccess && {
          isSaveQuoteSuccess: action.payload.isSaveQuoteSuccess,
        }),
        emailAddress: action.payload.emailAddress,
        currentQuote: { ...action.payload.saveQuote },
      };

      return savedQuoteData;

    case INSERT_QUOTE:
      var quoteData = {
        ...state,
        emailAddress: action.payload.emailAddress,
        insertedQuote: { ...action.payload.insertQuotePayload },
      };

      return quoteData;
    case ERROR_SAVE_QUOTE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_SAVE_QOUTE_SUCCESS:
      return {
        ...state,
        isSaveQuoteSuccess: false,
      };
    case SAVE_INCOMPLETE_POLICY_DETAILS: {
      const { quoteId, quoteHistoryId, insurancePolicyId, emailAddress } = action.payload;
      return {
        ...state,
        emailAddress: emailAddress,
        isSaveQuoteSuccess: true,
        currentQuote: {
          ...state.currentQuote,
          policyQuoteId: quoteId,
        },
        insertedQuote: {
          ...state.insertedQuote,
          insurancePolicyId: insurancePolicyId,
          quoteId: quoteId,
          quoteHistoryId: quoteHistoryId,
          customerDetails: {
            ...state.insertedQuote.customerDetails,
            emailAddress: emailAddress,
          },
        },
      };
    }
    case CLEAR_QUOTE:
      return initialState;
    default:
      return state;
  }
};

// action creators
export const requestSaveQuote = (payload) => ({
  type: REQUEST_SAVE_QUOTE,
  payload,
});

export const requestInsertQuote = (payload, callback) => ({
  type: REQUEST_INSERT_QUOTE,
  payload,
  callback,
});

export const requestCreatePolicy = (payload, callback) => ({
  type: REQUEST_CREATE_POLICY_AFTER_LOGIN,
  payload,
  callback,
});

export const resetSaveQuoteSuccess = () => ({
  type: RESET_SAVE_QOUTE_SUCCESS,
});

export const saveIncompletePolicyDetails = (payload) => ({
  type: SAVE_INCOMPLETE_POLICY_DETAILS,
  payload,
});

export const updateFrequencySaveQuote = (payload) => ({
  type: UPDATE_FREQUENCY_SAVE_QUOTE,
  payload,
});
//API Call
// const insertQuoteApi = async data => {
//   try {
//     return await axios.post(`enroll/create_policy`, data)
//   } catch (error) {
//     return error
//   }
// }

// const saveQuoteApi = async data => {
//   try {
//     return await axios.post(`enroll/save_policy`, data)
//   } catch (error) {
//     return error
//   }
// }

// sagas
function* saveQuote({ payload }) {
  try {
    let saveQuoteData = yield select(insertQuoteSelector);

    let { responseCode, result } = yield call(Api.saveQuote, {
      ...saveQuoteData,
      customerDetails: {
        ...saveQuoteData.customerDetails,
        emailAddress: payload.email,
      },
    });

    if (responseCode === 200) {
      const { insurancePolicyId, quoteHistoryId, quoteId } = result;

      let insertQuotePayload = {
        ...saveQuoteData,
        insurancePolicyId,
        quoteHistoryId,
        quoteId,
        customerDetails: {
          ...saveQuoteData.customerDetails,
          emailAddress: payload.email,
        },
      };
      yield put({
        type: INSERT_QUOTE,
        payload: { insertQuotePayload },
      });

      yield put({
        type: SAVE_QUOTE,
        payload: {
          saveQuote: {
            policyQuoteId: quoteId,
          },
          isSaveQuoteSuccess: true,
          emailAddress: payload.email,
        },
      });
      yield put(pushReferrer('/enroll/customer-info/'));
    } else if (responseCode === 417) {
      yield put(
        addError({
          key: 'emailError',
          message: 'This email address is already in use',
        }),
      );
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
    yield put(unsetLoading());
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* insertQuote({ payload, callback }) {
  const { EXISTING_EMAIL_ENTERED } = events;
  yield put(setGlobalLoading(`Generating Coverage...`));
  try {
    let insertQuoteData = yield select(insertQuoteSelector);

    let {
      responseCode,
      message = '',
      result,
    } = yield call(Api.insertQuote, {
      ...insertQuoteData,
      customerDetails: {
        ...insertQuoteData.customerDetails,
        emailAddress: payload.email,
      },
    });

    if (responseCode === 200) {
      const { insurancePolicyId, quoteHistoryId, quoteId, homebodyUserId, homebodyUuid } = result;
      let insertQuotePayload = {
        ...insertQuoteData,
        insurancePolicyId,
        quoteHistoryId,
        quoteId,
        customerDetails: {
          ...insertQuoteData.customerDetails,
          emailAddress: payload.email,
        },
      };

      const { USER_ID } = analyticsProperties;
      const digitalAnalyticsProps = {
        userProperties: [{ name: USER_ID, value: homebodyUuid }],
      };
      sync(digitalAnalyticsProps);

      yield put(
        updateUser({
          homebodyUserId,
          homebodyUuid,
        }),
      );
      yield put({
        type: INSERT_QUOTE,
        payload: { insertQuotePayload },
      });

      yield put({
        type: SAVE_QUOTE,
        payload: {
          saveQuote: {
            policyQuoteId: quoteId,
          },
          emailAddress: payload.email,
        },
      });
      typeof callback == 'function' && callback();
      yield delay(1500);
      yield put({ type: REDIRECTED });
    } else if (responseCode === 417) {
      yield put(
        addError({
          key: 'emailError',
          message: 'This email address is already in use.',
        }),
      );
      const digitalAnalyticsProps = {
        event: { name: EXISTING_EMAIL_ENTERED },
      };
      sync(digitalAnalyticsProps);
    } else if (responseCode === 419) {
      yield put(
        addError({
          key: 'serverError',
          message,
        }),
      );
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
    yield put(unsetLoading());
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
  yield put(unsetGlobalLoading());
}

function* createPolicyAfterLogin({ payload, callback }) {
  yield put(setGlobalLoading('Generating Coverage...'));
  try {
    let insertQuoteData = yield select(insertQuoteSelector);

    let {
      responseCode,
      message = '',
      result,
    } = yield call(Api.createPolicyAfterLogin, {
      ...insertQuoteData,
      customerDetails: {
        ...insertQuoteData.customerDetails,
        emailAddress: payload.email,
      },
      isAfterLoggedIn: payload.isLoggedIn,
      entityId: payload.entityId,
    });

    if (responseCode === 200) {
      const { insurancePolicyId, quoteHistoryId, quoteId, paymentDetails } = result;
      let insertQuotePayload = {
        ...insertQuoteData,
        insurancePolicyId,
        quoteHistoryId,
        quoteId,
        customerDetails: {
          ...insertQuoteData.customerDetails,
          emailAddress: payload.email,
        },
      };

      yield all([
        put({
          type: INSERT_QUOTE,
          payload: { insertQuotePayload },
        }),
        put({
          type: SAVE_QUOTE,
          payload: {
            saveQuote: {
              policyQuoteId: quoteId,
            },
            emailAddress: payload.email,
          },
        }),
        typeof callback == 'function' && callback(),
        delay(1500),
        put({ type: REDIRECTED }),
      ]);

      if (Object.keys(paymentDetails).length) {
        let paymentInfoData = yield select(paymentInfoSelector);

        yield put(
          updatePaymentInfo({
            ...paymentInfoData,
            billingState: paymentDetails.billingStateCode,
            paymentType: paymentDetails.paymentTypeId == 4 ? 'eCheck' : 'credit',
            checkAccountTypeId: paymentDetails.accountTypeId,
            checkNameOnAccount: paymentDetails.accountName,
            checkRoutingNumber: paymentDetails.routingNumber,
            checkAccountNumber: paymentDetails.accountNumber,
            ccNameOnCard: paymentDetails.nameOnCard,
            ccExpDateMonth: paymentDetails.expMonth,
            ccExpDateYear: paymentDetails.expYear,
            ccCardNumberMasked: paymentDetails.ccNumber,
            billingStreetLine: paymentDetails.billingStreetLine,
            billingCity: paymentDetails.billingCity,
            billingPostalCode: paymentDetails.billingPostalCode,
            paymentTypeId: paymentDetails.paymentTypeId,
            checkBankName: paymentDetails.checkBankName,
          }),
        );
      }
    } else if (responseCode === 417) {
      yield put(
        addError({
          key: 'emailError',
          message: 'This email address is already in use',
        }),
      );
    } else if (responseCode === 419) {
      yield put(
        addError({
          key: 'serverError',
          message,
        }),
      );
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
  yield put(unsetGlobalLoading());
}

function* watchInsertQuote() {
  yield takeLatest(REQUEST_INSERT_QUOTE, insertQuote);
}

function* watchSaveQuote() {
  yield takeLatest(REQUEST_SAVE_QUOTE, saveQuote);
}

function* watchCreatePolicyAfterLogin() {
  yield takeLatest(REQUEST_CREATE_POLICY_AFTER_LOGIN, createPolicyAfterLogin);
}

export const sagas = [watchSaveQuote, watchInsertQuote, watchCreatePolicyAfterLogin];
