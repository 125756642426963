import Api from 'api';
import { call, put, takeLatest } from 'redux-saga/effects';

// action types
const REQUEST_PAYMENT_TYPES = 'ri/paymentTypes/REQUEST_PAYMENT_TYPES';
const RECEIVE_PAYMENT_TYPES = 'ri/paymentTypes/RECEIVE_PAYMENT_TYPES';

// reducer
export default (state = {}, action = {}) => {
  switch (action.type) {
    case RECEIVE_PAYMENT_TYPES:
      return action.payload;
    default:
      return state;
  }
};

// action creators
export const requestPaymentTypes = (payload) => ({
  type: REQUEST_PAYMENT_TYPES,
  payload,
});

export const receivePaymentTypes = (payload) => ({
  type: RECEIVE_PAYMENT_TYPES,
  payload,
});

// sagas
function* getPaymentTypes(action) {
  try {
    const types = yield call(Api.getPaymentTypes, action.payload);
    yield put(receivePaymentTypes(types));
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* watchPaymentTypes() {
  yield takeLatest(REQUEST_PAYMENT_TYPES, getPaymentTypes);
}

export const sagas = [watchPaymentTypes];
