// action types
const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
const UNSET_REDIRECT_URL = 'UNSET_REDIRECT_URL';

// reducer
export default (state = '', action = {}) => {
  switch (action.type) {
    case SET_REDIRECT_URL:
      return action.payload;
    case UNSET_REDIRECT_URL:
      return '';
    default:
      return state;
  }
};

// action creators
export const setRedirectUrl = (payload) => ({ type: SET_REDIRECT_URL, payload });
export const unsetRedirectUrl = () => ({ type: UNSET_REDIRECT_URL });
