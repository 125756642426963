/* eslint-disable */
import Api from 'api';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { USER_LOGOUT } from './user';
// import { receivePaymentMethods } from './paymentMethods';
// import { receivePaymentTypes } from './paymentTypes';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { validateToken } from './user';


// action types
export const EDIT_PAYMENT_INFO = 'ri/payments/EDIT_PAYMENT_INFO';

const REQUEST_PAYMENT_HISTORY = 'ri/payments/REQUEST_PAYMENT_HISTORY';
const RECEIVE_UPCOMING_PAYMENTS = 'ri/payments/RECEIVE_UPCOMING_PAYMENTS';
export const RECEIVE_PAYMENTS_HISTORY = 'ri/payments/RECEIVE_PAYMENTS_HISTORY';
const REQUEST_PAYMENT_DATA = 'ri/payments/REQUEST_PAYMENT_DATA';
const SET_PAYMENTS_FILTER_DETAILS = 'ri/payments/SET_PAYMENTS_FILTER_DETAILS';
export const CLEAR_UPCOMING_PAYMENTS = 'ri/payments/CLEAR_UPCOMING_PAYMENTS';
export const UPDATE_PAYMENT_FAILED = 'ri/payments/UPDATE_PAYMENT_FAILED';
const GENERATE_UNIQUE_AGENT_CODE  = 'ri/payments/GENERATE_UNIQUE_AGENT_CODE';
const VALIDATE_PAYMENT_COMPLETION = 'ri/payments/VALIDATE_PAYMENT_COMPLETION';
const NEW_UNIQUE_CODE = 'ri/payments/NEW_UNIQUE_CODE';
const PAYMENT_COMPLETION_DATA = 'ri/payments/PAYMENT_COMPLETION_DATA';


const initialState = {
  upcomingPayments: {},
  upcomingPaymentsLoaded: false,
  paymentHistory: [],
  paymentHistoryLoaded: false,
  paymentHistoryMaxDate: '',
  paymentHistoryMinDate: '',
  visibleCount: 10,
  dateRange: [],
  uniqueAgentCode: '0000',
  maskedCardNumber: '',
  secureReferenceId: '',
  paymentTypeId: '',
};

// reducer
export default (state = initialState, action) => {
  const handleReceivePaymentsHistory = () => {
    const { paymentHistoryMinDate, paymentHistoryMaxDate, paymentHistory } =
      action.payload;
    return {
      ...state,
      paymentHistoryLoaded: true,
      paymentHistory,
      paymentHistoryMaxDate,
      paymentHistoryMinDate,
    };
  };

  const handleUpdatePaymentFailed = () => {
    const { isPaymentFailed, insurancePolicyId } = action.payload;
    return {
      ...state,
      upcomingPayments: {
        ...state['upcomingPayments'],
        ...(state['upcomingPayments'][insurancePolicyId] && {
          [insurancePolicyId]: {
            ...state['upcomingPayments'][insurancePolicyId],
            isPaymentFailed,
          },
        }),
      },
    };
  };

  switch (action.type) {
    // case RECEIVE_POLICIES: {
    //   return {
    //     ...state,
    //     payments: action.payload.payments,
    //   }
    // }
    case NEW_UNIQUE_CODE:
        return { 
          ...state,
          maskedCardNumber: '',
          secureReferenceId: '',
          paymentTypeId: '',
          uniqueAgentCode: action.uniqueAgentCode 
        };
    case PAYMENT_COMPLETION_DATA:
        return {
          ...state, 
          maskedCardNumber: action.result.ccLastFour,
          secureReferenceId: action.result.secureReferenceId,
          paymentTypeId: action.result.paymentTypeId,
        };
    case CLEAR_UPCOMING_PAYMENTS:
      return {
        ...state,
        upcomingPayments: {},
        upcomingPaymentsLoaded: false,
      };
    case RECEIVE_PAYMENTS_HISTORY:
      return handleReceivePaymentsHistory();
    case RECEIVE_UPCOMING_PAYMENTS:
      return {
        ...state,
        upcomingPaymentsLoaded: true,
        upcomingPayments: action.payload,
      };
    case EDIT_PAYMENT_INFO:
      return {
        ...state,
        [action.paymentId]: {
          ...state[action.paymentId],
          paymentMethod: action.paymentMethodId,
        },
      };
    case UPDATE_PAYMENT_FAILED:
      return handleUpdatePaymentFailed();
    case SET_PAYMENTS_FILTER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

// action creators
export const editPaymentInfo = payload => ({
  type: EDIT_PAYMENT_INFO,
  payload,
});

export const receiveUpcomingPayments = payload => ({
  type: RECEIVE_UPCOMING_PAYMENTS,
  payload,
});

export const receivePaymentHistory = payload => ({
  type: RECEIVE_PAYMENTS_HISTORY,
  payload,
});

export const requestPaymentData = payload => ({
  type: REQUEST_PAYMENT_DATA,
  payload,
});

export const requestPaymentHistory = payload => ({
  type: REQUEST_PAYMENT_HISTORY,
  payload,
});

export const setPaymentsFilterDetails = payload => ({
  type: SET_PAYMENTS_FILTER_DETAILS,
  payload,
});

export const generateUniqueAgentCode = payload => ({
  type: GENERATE_UNIQUE_AGENT_CODE,
  payload,
});

export const validatePaymentCompletion = payload => ({
  type: VALIDATE_PAYMENT_COMPLETION,
  payload,
});

// sagas
function* getPayments({ payload }) {
  yield put(setGlobalLoading(`Loading payment data...`));
  try {
    const response = yield call(Api.getUpcomingPayments, payload);
    if (response.responseCode === 200) {
      yield put(receiveUpcomingPayments(response.result));
    } else if (response.responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
  yield put(unsetGlobalLoading());
}

function* getPaymentHistory({ payload }) {
  yield put(setGlobalLoading(`Loading payment history...`));
  try {
    const { responseCode, result } = yield call(Api.getPaymentHistory, payload);
    if (responseCode === 200) {
      const { paymentHistory, minPaymentDate, maxPaymentDate } = result;

      yield put(
        receivePaymentHistory({
          paymentHistory,
          paymentHistoryMaxDate: maxPaymentDate,
          paymentHistoryMinDate: minPaymentDate,
        })
      );
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else if (responseCode === 417) {
      yield put(
        receivePaymentHistory({
          paymentHistory: [],
          paymentHistoryMaxDate: '',
          paymentHistoryMinDate: '',
        })
      );
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log('failed', error.toString());
  }
  yield put(unsetGlobalLoading());
}

function* generateUniqueAgentCodeWorker({ payload }) {
  yield put(setGlobalLoading(`Generating IVR Code...`));
  
    try {
        const {responseCode,result} = yield call(Api.generateUniqueAgentCode, payload);
        const  uniqueAgentCode  = result.uniqueAgentCode;
   
        if (responseCode === 200) {
          yield put({type: NEW_UNIQUE_CODE , uniqueAgentCode})
        } else if (responseCode === 203) {
          yield put(validateToken({ isValidToken: false }));
        }
    } 
    catch (err) {
      if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
      console.log(err.toString());
    }
    yield put(unsetGlobalLoading());
}

function* validatePaymentCompletionCodeWorker({ payload }) {
 
  yield put(setGlobalLoading(`Validating Payment Completion...`));
    try {
        const {responseCode,result} = yield call(Api.validatePaymentCompletion, payload);
        if (responseCode === 200) {
          yield put({type: PAYMENT_COMPLETION_DATA , result})
        } else if (responseCode === 203) {
          yield put(validateToken({ isValidToken: false }));
        }
    } 
    catch (err) {
      if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
      console.log(err.toString());
    }
    yield put(unsetGlobalLoading());
}

function* watchGetPayments() {
  yield takeLatest(REQUEST_PAYMENT_DATA, getPayments);
}

function* watchGetPaymentHistory() {
  yield takeLatest(REQUEST_PAYMENT_HISTORY, getPaymentHistory);
}

function* watchGenerateAgentCode() {
  

    yield takeLatest(GENERATE_UNIQUE_AGENT_CODE, generateUniqueAgentCodeWorker);
}

function* watchValidatePaymentCompletion() {
 
  yield takeLatest(VALIDATE_PAYMENT_COMPLETION, validatePaymentCompletionCodeWorker);
}

export const sagas = [
  watchGetPayments,
  watchGetPaymentHistory,
  watchGenerateAgentCode,
  watchValidatePaymentCompletion,
];
