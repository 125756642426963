import {
  UPDATE_BILLING_FREQUENCY,
  UPDATE_LIMIT_VALUE,
  UPDATE_ENDORSEMENT_VALUE,
  TOGGLE_ENDORSEMENT,
} from './quoteValues';

import { SET_PRODUCTS, SET_START_DATE, SET_PROPERTY, SET_ZIP } from './getQuote';

// reducer
export default (state = Date.now(), action = {}) => {
  switch (action.type) {
    case UPDATE_BILLING_FREQUENCY:
    case UPDATE_LIMIT_VALUE:
    case UPDATE_ENDORSEMENT_VALUE:
    case TOGGLE_ENDORSEMENT:
    case SET_PRODUCTS:
    case SET_START_DATE:
    case SET_PROPERTY:
    case SET_ZIP:
      return Date.now();
    default:
      return state;
  }
};
