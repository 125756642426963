// action types
const SET_LOADING = 'ri/loading/SET_LOADING';
const UNSET_LOADING = 'ri/loading/UNSET_LOADING';

// reducer
export default (state = false, action = {}) => {
  switch (action.type) {
    case SET_LOADING:
      return true;
    case UNSET_LOADING:
      return false;
    default:
      return state;
  }
};

// action creators
export const setLoading = () => ({ type: SET_LOADING });
export const unsetLoading = () => ({ type: UNSET_LOADING });
