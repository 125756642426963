import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const PlaceholderContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const TimelineWrapper = styled('div')`
  flex: 1;
  margin-bottom: 24px;
  margin-right: 24px;
`;

const TimelineItem = styled('div')`
  background: white;
  border: 1px solid;
  border-color: ${({ theme }) => theme.tertiary.medium};
  border-radius: 3px;
  border-top: 4px solid ${({ theme }) => theme.primary.base};
  padding: 16px;

  margin: 0;
  min-width: 328px;
  min-height: 200px;
`;

const animatedBackground = css`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -464px 0;
    }

    100% {
      background-position: 464px 0;
    }
  }

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 880px 104px;
  position: relative;
`;

const TopHeader = styled('div')`
  height: 48px;
  ${animatedBackground};
`;

const ContentSeparator = styled('div')`
  height: 24px;
  background: white;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
`;

const HeaderWrapper = styled('div')`
  flex: 1;
`;

const Header = styled('div')`
  width: 96px;
  height: 16px;
  ${animatedBackground};
`;

const HeaderBottom = styled('div')`
  height: 16px;
`;

const SubHeader = styled('div')`
  width: 160px;
  height: 24px;
  ${animatedBackground};
`;

const SideBar = styled('div')`
  width: 104px;
  height: 56px;
  ${animatedBackground};
`;

const Content = styled('div')`
  width: 280px;
  height: 32px;
  ${animatedBackground};
`;

const Footer = styled('div')`
  height: 48px;
  ${animatedBackground};
`;

function PlaceholderContent() {
  return (
    <TimelineWrapper>
      <TimelineItem>
        <TopHeader />
        <ContentSeparator />
        <Wrapper>
          <HeaderWrapper>
            <Header />
            <HeaderBottom />
            <SubHeader />
          </HeaderWrapper>
          <SideBar />
        </Wrapper>
        <ContentSeparator />
        <Header />
        <HeaderBottom />
        <Content />
        <ContentSeparator />
        <Header />
        <HeaderBottom />
        <Content />
        <ContentSeparator />
        <Footer />
      </TimelineItem>
    </TimelineWrapper>
  );
}

function CurrentPoliciesLoader() {
  return (
    <PlaceholderContainer>
      <PlaceholderContent />
      <PlaceholderContent />
    </PlaceholderContainer>
  );
}

export default CurrentPoliciesLoader;
