const insuranceCarrierTypes = {
  MARKEL: 1,
  KEMPER: 71,
  KEMPER_DIRECT: 72,
  QBE: 75,
  SSIS: 76,
  IDTHEFT: 77,
  ARRAY: 3031,
};

export default insuranceCarrierTypes;
