import { UPDATE_USER } from './user';
import { RECEIVED_ACTIVE_POLICIES } from './policies';
import { RECEIVE_PAYMENT_METHODS } from './paymentMethods';

// reducer
export default (state = Date.now(), action = {}) => {
  switch (action.type) {
    case RECEIVE_PAYMENT_METHODS:
    case RECEIVED_ACTIVE_POLICIES:
    case UPDATE_USER:
      return Date.now();
    default:
      return state;
  }
};
