import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from 'redux/modules';

import { loadOrExpireState, saveState } from 'utils/localStorage';
import { throttle, cherryPickKeys } from '@entrata/utils';

import sagas from './sagas';
/*
// Adding a axios request interceptor
axios.interceptors.request.use(
  config => {
    // Setting Api url
    config.baseURL = process.env.REACT_APP_API_URL
    console.log('base URL:', config.baseURL)
    console.log('process.env.REACT_APP_API_URL:', process.env.REACT_APP_API_URL)

    // Setting headers
    config.headers['Content-type'] = 'application/json'
    // config.headers['Origin'] = process.env.GATSBY_API_ORIGIN

    // Setting authorization token
    let token =
      localStorage.getItem('token') ||
      '86b9963b22a818459bd794b4174c2212424bf843c56570bf35cc833f0124bf24'
    token && (config.headers['Authorization'] = `Bearer ${token}`)

    if (config.method == 'get') {
      config.url = `${config.url}${
        config.url.indexOf('?') > -1 ? '&' : '?'
      }token=${token}`
    } else {
      config.data = {
        ...config.data,
        token,
      }
    }

    return config
  },
  error => Promise.reject(error)
)

// Adding a axios response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    const status =
      error.status ||
      ((error.response ? error.response.status : 0)(status === 401) && logout())
    return Promise.reject(error)
  }
)
*/
const localKeys = [
  'lastUpdated',
  'quote',
  'quotePackages',
  'quoteValues',
  'getQuote',
  'properties',
  'availableProducts',
  'enrollCustomerInfo',
  'enrollPaymentInfo',
  'pricing',
  'savedQuote',
  'legalNotices',
  'user',
];

/* eslint no-underscore-dangle: ["warn", { "allow": ["__REDUX_DEVTOOLS_EXTENSION__"] }] */
export default function configureStore(initialState = {}) {
  const localStateKey = 'ri-store';
  const localState = loadOrExpireState(localStateKey, 60000000) || {};
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    combineReducers(reducers),
    { ...localState, ...initialState },
    compose(
      applyMiddleware(sagaMiddleware),
      typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
    ),
  );
  // run the available sagas
  sagaMiddleware.run(sagas);

  // save some state to localStorage to populate quote/enrollment
  // if the user refreshes before submitting to the server
  store.subscribe(
    throttle(() => {
      const state = cherryPickKeys(localKeys)(store.getState());
      // const state = store.getState()
      // always do async save
      setTimeout(() => saveState(state, localStateKey), 0);
    }, 2000),
  );

  return store;
}
