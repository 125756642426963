import { delay } from 'redux-saga';
import Api from 'api';
import { select, call, put, takeLatest } from 'redux-saga/effects';
import { validateToken } from './user';

// Action Types

const VERIFIED_ADDRESS = 'ri/enrollment/VERIFIED_ADDRESS';
const VERIFIED_ADDRESS_STATUS = 'ri/enrollment/VERIFIED_ADDRESS_STATUS';
const UPDATE_BYPASS_ADDRESS = 'ri/enrollment/UPDATE_BYPASS_ADDRESS';

// Reducer

const initialState = {
  status: 'unverified',
  correctedAddress: '',
  err: '',
  isAddressByPass: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case VERIFIED_ADDRESS_STATUS:
      return {
        status: action.payload.status,
        err: action.payload.err,
        correctedAddress: action.payload.correctedAddress,
      };
    case UPDATE_BYPASS_ADDRESS:
      return {
        ...state,
        isAddressByPass: action.payload.isAddressByPass,
      };
    default:
      return state;
  }
};

export const verifyAddress = (payload) => ({
  type: VERIFIED_ADDRESS,
  payload,
});

export const setVerifiedAddressStatus = (payload) => ({
  type: VERIFIED_ADDRESS_STATUS,
  payload,
});

export const updateByPassAddress = (payload) => ({
  type: UPDATE_BYPASS_ADDRESS,
  payload,
});

function* onVerifyAddress({ payload }) {
  yield put(setVerifiedAddressStatus({ status: 'loading' }));
  yield delay(3000);
  const location = yield select(
    ({
      quote: {
        selectedProperty: { city, stateCode, postalCode },
      },
      savedQuote: {
        insertedQuote: { userId, employeeId, cid, customerId, leaseId, propertyId, leadSourceTypeId },
      },
      enrollCustomerInfo: {
        nameFirst,
        nameLast,
        emailAddress,
        phoneNumber,
        streetLine,
        password,
        isSameMailingAddress,
        mailingStreetLine,
        mailingCity,
        mailingState,
        mailingPostalCode,
      },
      properties,
    }) => ({
      userId: userId || '',
      employeeId: employeeId || '',
      cid: cid || '',
      propertyId: propertyId || '',
      leadSourceTypeId: leadSourceTypeId || '',
      customerId: customerId || '',
      leaseId: leaseId || '',
      isTest: parseInt(properties[propertyId].isActive, 10) === 0,
      propertyStreetLine: properties[propertyId].propertyStreetLine,
      customerDetails: {
        nameFirst: nameFirst || '',
        nameLast: nameLast || '',
        emailAddress: emailAddress || '',
        phoneNumber: phoneNumber || '',
        password: password || '',
        streetAddress: streetLine || '',
        isSameMailingAddress: isSameMailingAddress || false,
        city: city || '',
        stateCode: stateCode || '',
        postalCode: postalCode || '',
        mailingStreetAddress: mailingStreetLine || '',
        mailingState: mailingState || '',
        mailingCity: mailingCity || '',
        mailingPostalCode: mailingPostalCode || '',
      },
    }),
  );

  const response = yield call(Api.validateAddress, {
    ...location,
    customerDetails: {
      ...location.customerDetails,
      streetAddress: payload.value,
    },
  });

  const { responseCode, message } = response;

  if (responseCode === 203) {
    yield put(validateToken({ isValidToken: false }));
  } else if (responseCode === 200 && message === 'Success') {
    yield put(setVerifiedAddressStatus({ status: 'valid' }));
  } else if (responseCode === 200 && message !== 'Success') {
    yield put(
      setVerifiedAddressStatus({
        status: 'valid',
        correctedAddress: message,
      }),
    );
  } else {
    yield put(
      setVerifiedAddressStatus({
        status: 'error',
        err: '',
      }),
    );
  }
}

function* watchVerifyAddress() {
  yield takeLatest(VERIFIED_ADDRESS, onVerifyAddress);
}

export const sagas = [watchVerifyAddress];
