import extend from 'extend';
import isObject from './isObject';

export default (obj, keysArr = []) => {
  const copy = extend(true, {}, obj);

  keysArr.forEach((key) => {
    if (typeof key === 'string') {
      delete copy[key];
    } else if (isObject(key)) {
      Object.keys(key).forEach((k) => key[k].forEach((sub) => delete copy[k][sub]));
    }
  });

  return copy;
};
