/* eslint-disable */
import Api from 'api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { validateToken } from './user';

export const REQUEST_LEGAL_NOTICES = 'ri/legalNotices/REQUEST_LEGAL_NOTICES';
export const RECEIVE_LEGAL_NOTICES = 'ri/legalNotices/RECEIVE_LEGAL_NOTICES';
export const SET_LEGAL_NOTICES_DATA = 'ri/legalNotices/SET_LEGAL_NOTICES_DATA';

const initialState = {
  notices: [],
  nextPostOnDays: 10,
  isLoadingNotices: false,
  stateCode: '',
  insuranceCarrierId: '',
};

// reducer
export default (state = initialState, { type, payload }) => {
  const handleSetLegalNoticesData = () => {
    const { stateCode, insuranceCarrierId } = payload;

    return {
      ...state,
      stateCode,
      insuranceCarrierId,
    };
  };

  switch (type) {
    case REQUEST_LEGAL_NOTICES:
      return {
        ...state,
        isLoadingNotices: true,
      };
    case RECEIVE_LEGAL_NOTICES:
      return {
        ...state,
        ...payload,
        isLoadingNotices: false,
      };
    case SET_LEGAL_NOTICES_DATA:
      return handleSetLegalNoticesData();
    default:
      return state;
  }
};

//actions
export const requestLegalNotices = payload => ({
  type: REQUEST_LEGAL_NOTICES,
  payload,
});

export const setLegalNoticesData = payload => ({
  type: SET_LEGAL_NOTICES_DATA,
  payload,
});

//sagas
function* getLegalNotices({ payload }) {
  try {
    let { responseCode, result } = yield call(Api.getLegalNotices, payload);
    if (responseCode === 200) {
      yield put({
        type: RECEIVE_LEGAL_NOTICES,
        payload: {
          notices: result.legalNotices,
          nextPostOnDays: result.nextPostOnDays,
        },
      });
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put({
        type: RECEIVE_LEGAL_NOTICES,
        payload: {
          notices: [],
          nextPostOnDays: 10,
        },
      });
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('error => ', err.message);
  }
}

function* watchGetLegalNotices() {
  yield takeLatest(REQUEST_LEGAL_NOTICES, getLegalNotices);
}

export const sagas = [watchGetLegalNotices];
