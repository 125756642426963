/* eslint-disable */
import { USER_LOGOUT } from './user'

// action types
const ADD_ERROR = 'ri/errors/ADD_ERROR'
const REMOVE_ERROR = 'ri/errors/REMOVE_ERROR'

// reducer
export default (state = {}, action) => {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        [action.payload.key]: action.payload.message
      }
    case REMOVE_ERROR:
      return Object.values(state)
        .filter(({ key }) => key !== action.payload)
        .reduce(
          (obj, err) => ({
            ...obj,
            [err.key]: { ...err }
          }),
          {}
        )
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

// action creators
// expects an object of { key: string, message: node }
export const addError = payload => ({ type: ADD_ERROR, payload })
// expects a key (string) only
export const removeError = payload => ({ type: REMOVE_ERROR, payload })
