/* eslint-disable */
import Api from 'api';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { addError, removeError } from './errors';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { delay } from 'redux-saga';
import { clearLocalState } from 'utils/localStorage';
import { CLEAR_QUOTE_VALUES } from './quoteValues';
import { CLEAR_QUOTE } from './quote';
import { CLEAR_ENROLLMENT } from './enrollment';
import { setLegalNoticesData } from './legalNotices';
import { CLEAR_PRICING } from './pricing';
import { CLEAN_QUOTE_PACKAGES } from './quotePackages';
import { RECEIVE_LOCATION_DATA, CLEAR_PROPERTIES } from './properties';
import { clearReferrers, pushReferrer } from './referrers';
import { setVerifiedAddressStatus } from 'redux/modules/verifyAddress';
import { saveIncompletePolicyDetails } from 'redux/modules/savedQuote';
import { setRedirectUrl } from './redirectUrl';
import {
  setEmployee,
  setUser,
  setLease,
  setApplicant,
  setApplication,
  setLeadSourceType,
  setProperty,
  setZip,
  setStartDate,
  setActiveStep,
  setStateAtStep,
  setClient,
  setCustomer,
  RESET_STEPS,
  SET_CURRENT_STEP_ERROR,
} from './getQuote';
import formatISO from 'date-fns/format';
import { QUOTE_VERSION_V2 } from 'constants/common';

// action types
const ATTEMPT_LOGIN = 'ri/user/ATTEMPT_LOGIN';
const CREATE_ACCOUNT = 'ri/user/CREATE_ACCOUNT';
export const USER_LOGOUT = 'ri/user/USER_LOGOUT';
export const CLEAR_USER = 'ri/user/CLEAR_USER';
export const UPDATE_USER = 'ri/user/UPDATE_USER';
const CLEAR_CURRENT_PROCESS = 'ri/user/CLEAR_CURRENT_PROCESS';
export const UPDATE_PROFILE = 'ri/user/UPDATE_PROFILE';
const ATTEMPT_FORGOT_PASSWORD = 'ri/user/ATTEMPT_FORGOT_PASSWORD';
const ATTEMPT_RESET_PASSWORD = 'ri/user/ATTEMPT_RESET_PASSWORD';
const ATTEMPT_CHANGE_PASSWORD = 'ri/user/ATTEMPT_CHANGE_PASSWORD';
const REQUEST_TOKEN = 'ri/user/REQUEST_TOKEN';
export const SET_TOKEN = 'ri/user/SET_TOKEN';
const VALIDATE_TOKEN = 'ri/user/VALIDATE_TOKEN';
const CONSUMER_REQUEST = 'ri/user/CONSUMER_REQUEST';
const CONSUMER_REQUEST_UPDATE = 'ri/user/CONSUMER_REQUEST_UPDATE';
const UPDATE_IS_AGENT = 'ri/user/UPDATE_IS_AGENT';
const SET_SUCCESS_MESSAGE = 'ri/user/SET_SUCCESS_MESSAGE';
const SET_SERVER_CURRENT_DATE = 'ri/user/SET_SERVER_CURRENT_DATE';
const SET_PAYMENT_TERMS_AND_CONDITION_DATA = 'ri/user/SET_PAYMENT_TERMS_AND_CONDITION_DATA';
const UPDATE_IDTHEFT_POLICY_PAYMENT_FAILED = 'ri/user/UPDATE_IDTHEFT_POLICY_PAYMENT_FAILED';
const UPDATE_ALLOW_REAL_TIME_PAYMENT = 'ri/user/UPDATE_ALLOW_REAL_TIME_PAYMENT';
const UPDATE_PAYMENT_TIMEOUT = 'ri/user/UPDATE_PAYMENT_TIMEOUT';

const initialState = {
  isValidToken: true,
  isConsumerRequest: false,
  isAgent: true,
  paymentTermsAndConditionData: {},
  isIdTheftPaymentFailed: false,
  idTheftPaymentFailedReason: '',
  isAllowedRealtimePayment: false,
  isPaymentTimeout: false,
};

// reducer
export default (state = initialState, action) => {
  const handleSetToken = () => {
    let token = action.payload.token;
    localStorage.setItem('token', token);

    return {
      ...state,
      token,
    };
  };

  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT:
      clearLocalState('ri-store');
      return initialState;
    case CLEAR_USER:
      clearLocalState('ri-store');
      return initialState;
    case SET_PAYMENT_TERMS_AND_CONDITION_DATA: {
      return {
        ...state,
        paymentTermsAndConditionData: action.payload,
      };
    }
    case SET_TOKEN:
      return handleSetToken();
    case VALIDATE_TOKEN:
      return {
        ...state,
        isValidToken: action.payload.isValidToken,
      };
    case CONSUMER_REQUEST_UPDATE:
      return {
        ...state,
        isConsumerRequest: action.payload.isConsumerRequest,
      };
    case UPDATE_IS_AGENT:
      return {
        ...state,
        isAgent: action.payload.isAgent,
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload.message,
      };
    case SET_SERVER_CURRENT_DATE:
      return {
        ...state,
        serverCurrentDate: action.payload.serverCurrentDate,
      };
    case UPDATE_IDTHEFT_POLICY_PAYMENT_FAILED:
      return {
        ...state,
        isIdTheftPaymentFailed: action.payload.isIdTheftPaymentFailed,
        idTheftPaymentFailedReason: action.payload.idTheftPaymentFailedReason,
      };
    case UPDATE_ALLOW_REAL_TIME_PAYMENT:
      return {
        ...state,
        isAllowedRealtimePayment: action.payload.isAllowedRealtimePayment,
      };
    case UPDATE_PAYMENT_TIMEOUT:
      return {
        ...state,
        isPaymentTimeout: action.payload.isPaymentTimeout,
      };
    default:
      return state;
  }
};

// action creators
export const attemptLogin = (payload) => ({ type: ATTEMPT_LOGIN, payload });
export const createAccount = (payload) => ({ type: CREATE_ACCOUNT, payload });
export const updateUser = (payload) => ({ type: UPDATE_USER, payload });
export const logout = () => ({ type: USER_LOGOUT });
export const clearUser = () => ({ type: CLEAR_USER });
export const clearProcess = () => ({ type: CLEAR_CURRENT_PROCESS });
export const updateProfile = (payload) => ({
  type: UPDATE_PROFILE,
  payload,
});
export const attemptForgotPassword = (payload) => ({
  type: ATTEMPT_FORGOT_PASSWORD,
  payload,
});
export const attemptResetPassword = (payload) => ({
  type: ATTEMPT_RESET_PASSWORD,
  payload,
});
export const attemptChangePassword = (payload) => ({
  type: ATTEMPT_CHANGE_PASSWORD,
  payload,
});
export const requestToken = (payload) => ({
  type: REQUEST_TOKEN,
  payload,
});
export const setToken = (payload) => ({
  type: SET_TOKEN,
  payload,
});
export const validateToken = (payload) => ({
  type: VALIDATE_TOKEN,
  payload,
});

export const consumerRequest = (payload) => ({
  type: CONSUMER_REQUEST,
  payload,
});

export const consumerRequestUpdate = (payload) => ({
  type: CONSUMER_REQUEST_UPDATE,
  payload,
});

export const updateIsAgent = (payload) => ({
  type: UPDATE_IS_AGENT,
  payload,
});

export const setSuccessMessage = (payload) => ({
  type: SET_SUCCESS_MESSAGE,
  payload,
});

export const setPaymentTermsAndConditionData = (payload) => ({
  type: SET_PAYMENT_TERMS_AND_CONDITION_DATA,
  payload,
});

export const updateIdTheftPolicyPaymentFailed = (payload) => ({
  type: UPDATE_IDTHEFT_POLICY_PAYMENT_FAILED,
  payload,
});

export const updateAllowRealTimePayment = (payload) => ({
  type: UPDATE_ALLOW_REAL_TIME_PAYMENT,
  payload,
});

export const updatePaymentTimeout = (payload) => ({
  type: UPDATE_PAYMENT_TIMEOUT,
  payload,
});

// sagas
function* login({ payload }) {
  try {
    yield put(setGlobalLoading(`Authenticating User...`));
    const { responseCode, result, message } = yield call(Api.login, payload);
    if (responseCode === 200) {
      const {
        nameFirst,
        nameLast,
        entityId,
        token,
        emailAddress,
        phoneNumber,
        employeeId,
        employeeName,
        paymentTermsAndConditionData,
        homebodyUserId,
        homebodyUserUuid,
      } = result;
      const updatedUser = {
        nameFirst,
        nameLast,
        entityId,
        homebodyUserId,
        token,
        email: emailAddress,
        phoneNumber,
        homebodyUserUuid,
      };
      const hasRedirectUrl = Object.hasOwn(paymentTermsAndConditionData, 'redirectTo');

      yield clearCurrentProcess();
      if (hasRedirectUrl) {
        yield put(
          setPaymentTermsAndConditionData({
            key: payload.loginKey,
            ...paymentTermsAndConditionData,
          }),
        );
        yield put(setRedirectUrl(paymentTermsAndConditionData.redirectTo));
        /**
         * Unsetting the Loader in case of '/app/policies/add/' route because in this route
         * no api is getting called so Loader is not getting unset which causing UI to show loader continuously.
         * In case of other routes loader gets unset in those routes itself due to api calls.
         * @author: Akash Uphade <akash.uphade@entrata.com>
         */
        if (paymentTermsAndConditionData.redirectTo == '/app/policies/add/') {
          yield put(unsetGlobalLoading());
        }
      }
      yield put(updateUser(updatedUser));
      yield put(setToken({ token }));
      yield !!employeeId &&
        put(
          setEmployee({
            value: {
              id: employeeId,
              name: employeeName,
            },
          }),
        );
      yield delay(2000);
      yield !hasRedirectUrl && typeof payload.onComplete === 'function' && payload.onComplete();
    } else {
      yield put(
        addError({
          key: 'login',
          message: message,
        }),
      );
      yield delay(2000);
      yield put(unsetGlobalLoading());
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* forgotPassword({ payload }) {
  try {
    const response = yield call(Api.forgotPassword, payload);
    if (response.responseCode == 200) {
      let message = `If ${payload.emailAddress} is associated with a customer account in our records, you will receive an e-mail from us with instructions for resetting your password. If you don't receive this e-mail, be sure to check your junk mail folder as well.`;
      yield [put(setSuccessMessage({ message })), put(removeError('forgotPassword'))];
    } else {
      yield put(
        addError({
          key: 'forgotPassword',
          message: 'Sorry, this email isn`t in our system.',
        }),
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(Api.resetPassword, payload);
    if (response.responseCode === 200) {
      yield [put(setSuccessMessage({ message: 'Your password has been changed.' })), put(removeError('resetPassword'))];
      yield Object.hasOwn(payload, 'onComplete') && payload.onComplete();
    } else {
      yield put(
        addError({
          key: 'resetPassword',
          message: response.message,
        }),
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* changePassword(action) {
  try {
    const { message, responseCode } = yield call(Api.changePassword, action.payload);
    if (responseCode === 200) {
      yield [
        put(setSuccessMessage({ message: 'Your password has been changed.' })),
        put(removeError('changePassword')),
      ];
    } else {
      yield put(
        addError({
          key: 'changePassword',
          message,
        }),
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.log('failed', err.toString());
  }
}

function* clearCurrentProcess() {
  yield all([
    put({
      type: CLEAR_QUOTE_VALUES,
      payload: null,
    }),
    put({
      type: CLEAR_QUOTE,
      payload: null,
    }),
    put({
      type: RESET_STEPS,
      payload: null,
    }),
    put({
      type: CLEAR_ENROLLMENT,
      payload: null,
    }),
    put({
      type: CLEAR_PROPERTIES,
      payload: null,
    }),
    put({
      type: CLEAR_PRICING,
      payload: null,
    }),
    put(clearReferrers()),
    put(
      updateIsAgent({
        isAgent: true,
      }),
    ),
    put(
      setVerifiedAddressStatus({
        status: 'unverified',
        err: '',
        correctedAddress: '',
      }),
    ),
    put({
      type: CLEAN_QUOTE_PACKAGES,
      payload: null,
    }),
  ]);
}

function* finalizeAccount(action) {
  try {
    yield put(setGlobalLoading(`Updating User Account Information...`));

    const payload = action.payload;

    const result = yield call(Api.createAccount, payload.user);
    if (result.success == true) {
      yield put(
        updateUser({
          email: payload.user.emailAddress,
          ...payload.user,
        }),
      );
      yield clearCurrentProcess();
      yield delay(payload.delay);
      yield put(unsetGlobalLoading());
      Object.hasOwn(payload, 'onComplete') && payload.onComplete();
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(setGlobalLoading({ message: err.message, error: true }));
    yield delay(3000);
    yield put(unsetGlobalLoading());
    console.log('failed', err.toString());
  }
}

function* updateProfileProcess({ payload }) {
  try {
    const { responseCode, result, message } = yield call(Api.updateProfile, payload);

    if (responseCode === 200) {
      const { nameFirst, nameLast, emailAddress, phoneNumber } = result;
      yield put(
        updateUser({
          nameFirst,
          nameLast,
          email: emailAddress,
          phoneNumber,
        }),
      );
      yield put(
        addError({
          key: 'updateProfileSuccess',
          message: 'Your account information has been updated successfully',
        }),
      );
    } else if (responseCode === 203) {
      yield put(validateToken({ isValidToken: false }));
    } else {
      yield put(
        addError({
          key: 'updateProfileError',
          message: message,
        }),
      );
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    yield put(
      addError({
        key: 'updateProfileError',
        message: err.toString(),
      }),
    );
  }
}

function* getToken({ payload }) {
  try {
    let response = yield call(Api.getToken, payload);
    let {
      userId,
      leadSourceTypeId,
      employeeId,
      employeeName,
      propertyId,
      clientId,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      stateCode,
      insuranceCarrierId,
      serverCurrentDate,
      quoteId,
      quoteHistoryId,
      insurancePolicyId,
      emailAddress = '',
      employeeEmail = '',
    } = response;

    const isFromIncompletePolicy = quoteId && quoteHistoryId && insurancePolicyId && emailAddress;

    yield all([
      yield put(setToken(response)),
      yield put(validateToken({ isValidToken: true })),
      yield put(setUser({ value: userId })),
      yield put(setLease({ value: leaseId })),
      yield put(setApplicant({ value: applicantId })),
      yield put(setApplication({ value: applicationId })),
      yield put(setLeadSourceType({ value: leadSourceTypeId })),
      propertyId && (yield put(setProperty({ value: propertyId }))),
      yield put(setClient({ value: clientId })),
      yield put(setCustomer({ value: customerId })),
      yield put(
        setEmployee({
          value: {
            id: employeeId,
            name: employeeName || '',
            email: employeeEmail,
          },
        }),
      ),
      yield put(setLegalNoticesData({ stateCode, insuranceCarrierId })),
      yield put({
        type: SET_SERVER_CURRENT_DATE,
        payload: {
          serverCurrentDate,
        },
      }),
      isFromIncompletePolicy &&
        (yield put(
          saveIncompletePolicyDetails({
            quoteId,
            quoteHistoryId,
            insurancePolicyId,
            emailAddress,
          }),
        )),
    ]);

    if (response.result) {
      let { properties, isFlState } = response.result;

      if (properties && properties.length) {
        let property = properties[0];

        yield all([
          yield put({
            type: RECEIVE_LOCATION_DATA,
            payload: {
              properties: properties.reduce((acc, prop) => ({ [prop.propertyId]: prop, ...acc }), {}),
            },
          }),
          yield put(setZip({ value: property.postalCode })),
          yield put(
            setStartDate({
              value: formatISO(property.defaultCoverageStartDate),
            }),
          ),
          yield put(setStateAtStep({ step: 0, complete: false, stepAccessible: false })),
          yield put(setActiveStep({ activeStep: 0 })),
          yield put(setProperty({ value: property.propertyId })),
          isFromIncompletePolicy && (yield put(pushReferrer('/enroll/customer-info/'))),
        ]);
        if (typeof window !== 'undefined' && isFlState) {
          window.quoteVersion = QUOTE_VERSION_V2;
          yield typeof payload.setQuoteVersion === 'function' && payload.setQuoteVersion(QUOTE_VERSION_V2);
        }
      } else {
        if (response.responseCode === 417) {
          payload.onRequestFail && payload.onRequestFail();
          yield put({
            type: SET_CURRENT_STEP_ERROR,
            payload: {
              currentStepErrorDescription: response?.result?.detailMessage,
              currentStepError: response.message,
            },
          });
        }
      }
    } else {
      yield put(
        setStateAtStep({
          step: 0,
          complete: true,
          ignore: false,
          stepAccessible: true,
        }),
      );
      yield put(updateIsAgent({ isAgent: false }));
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log(error.message);
  }
}

function* getConsumerRequest({ payload }) {
  try {
    let response = yield call(Api.consumerRequestSubmit, payload);
    if (response.responseCode === 200) {
      yield put(
        consumerRequestUpdate({
          isConsumerRequest: true,
        }),
      );
    }
  } catch (error) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(error);
    console.log(error.message);
  }
}

function* watchConsumerRequest() {
  yield takeLatest(CONSUMER_REQUEST, getConsumerRequest);
}

function* watchGetToken() {
  yield takeLatest(REQUEST_TOKEN, getToken);
}

function* watchLogin() {
  yield takeLatest(ATTEMPT_LOGIN, login);
}

function* watchForgotPassword() {
  yield takeLatest(ATTEMPT_FORGOT_PASSWORD, forgotPassword);
}

function* watchResetPassword() {
  yield takeLatest(ATTEMPT_RESET_PASSWORD, resetPassword);
}

function* watchChangePassword() {
  yield takeLatest(ATTEMPT_CHANGE_PASSWORD, changePassword);
}

function* watchCreateAccount() {
  yield takeLatest(CREATE_ACCOUNT, finalizeAccount);
}

function* watchClearProcess() {
  yield takeLatest(CLEAR_CURRENT_PROCESS, clearCurrentProcess);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE, updateProfileProcess);
}

export const sagas = [
  watchLogin,
  watchForgotPassword,
  watchResetPassword,
  watchChangePassword,
  watchCreateAccount,
  watchClearProcess,
  watchUpdateProfile,
  watchGetToken,
  watchConsumerRequest,
];
