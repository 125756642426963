/* eslint-disable */
import { RECEIVE_LOCATION_DATA } from './properties'
export const RECEIVE_AVAILABLE_PRODUCTS = 'ri/availableProducts/RECEIVE_AVAILABLE_PRODUCTS'

// reducer
export default (state = [], action) => {
  switch (action.type) {
    case RECEIVE_LOCATION_DATA:
      return {
        ...state,
        ...(action.payload.availableProducts && {
          availableProducts: action.payload.availableProducts
        })
      }
    case RECEIVE_AVAILABLE_PRODUCTS:
      return action.payload.availableProducts
    default:
      return state
  }
}
