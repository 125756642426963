import { v4 } from 'uuid';

// action types
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// reducers
export default (state = [], action = {}) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload];
    case REMOVE_NOTIFICATION:
      return state.filter(({ id }) => id !== action.payload);
    default:
      return state;
  }
};

// action creators
export const addNotification = (payload) => ({
  type: ADD_NOTIFICATION,
  payload: { ...payload, id: v4() },
});

export const removeNotification = (payload) => ({
  type: REMOVE_NOTIFICATION,
  payload,
});
