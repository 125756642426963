import Api from 'api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { addError } from './errors';

// action types
export const REQUEST_UPDATE_ARRAY_USER = 'ri/userUpdate/REQUEST_UPDATE_USER';

// action creators
export const requestUpdateArrayUser = (payload) => ({
  type: REQUEST_UPDATE_ARRAY_USER,
  payload,
});

function* callUpdateArrayUser({ payload }) {
  yield put(setGlobalLoading(`Loading Identity Authentication...`));
  try {
    const { responseCode } = yield call(Api.updateArrayUser, payload.formData);
    if (responseCode === 200) {
      yield typeof payload.onComplete === 'function' && payload.onComplete();
    }
  } catch (err) {
    console.error('failed', err.toString());
    yield put(
      addError({
        key: 'arrayError',
        message: 'We are currently facing issues in accessing services. Please try again',
      }),
    );
  }

  yield put(unsetGlobalLoading());
}

function* watchUpdateArrayUser() {
  yield takeLatest(REQUEST_UPDATE_ARRAY_USER, callUpdateArrayUser);
}
export const sagas = [watchUpdateArrayUser];
