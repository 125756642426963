/* eslint-disable */
import React, { Component, createContext } from 'react'
import PropTypes from 'prop-types'
// import { navigate } from '@reach/router'
import { connect } from 'react-redux'
import { unsetRedirectUrl } from 'redux/modules/redirectUrl'
import { navigate } from 'gatsby'

const AuthContext = createContext()
export { AuthContext }

export class AuthProvider extends Component {
  state = {
    authenticated: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.email && !prevState.authenticated) {
      return { authenticated: true }
    } else if (!nextProps.user.email && prevState.authenticated) {
      return { authenticated: false }
    }
    return null
  }

  componentDidMount() {
    const returnUrl = typeof window !== 'undefined' ? window.location.pathname : '';
    localStorage.setItem('returnUrl', returnUrl);    

    const { location } = this.props
    if (this.state.authenticated) {
      this.handleLogin(true)
    }
    //  else {
    //   navigate(`/`)
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { authenticated } = this.state

    if (authenticated && !prevState.authenticated) {
      this.handleLogin(true)
    } else if (!authenticated && prevState.authenticated) {
      this.handleLogout(true)
    } else if (authenticated && prevState.authenticated) {
      this.handleLogin(true)
    }
  }

  handleLogin(noPush = false) {
    const { redirectUrl, unsetRedirectUrl, user } = this.props
    // temporary for dev to store user for 24 hrs
    // REMOVE LATER
    localStorage.setItem(
      'ri:loggedUser',
      JSON.stringify({
        ...user,
        loggedIn: Date.now(),
      })
    )
    // push to authenticated url
    if (!noPush) {
      navigate('/app/policies/', { replace: true })
    } else if (redirectUrl) {
      navigate(redirectUrl, { replace: true })
      unsetRedirectUrl()
    }
  }

  handleLogout() {
    // temporary for killing cached user
    // REMOVE LATER
    localStorage.removeItem('ri:loggedUser')
    navigate('/', { replace: true })
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

AuthProvider.propTypes = {
  children: PropTypes.any,
  redirectUrl: PropTypes.string,
  unsetRedirectUrl: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default connect(({ redirectUrl, user }) => ({ redirectUrl, user }), {
  unsetRedirectUrl,
})(AuthProvider)
