import { CLEAR_ENROLLMENT } from './enrollment';

// action types
const UPDATE_CUSTOMER_INFO = 'ri/enrollCustomerInfo/UPDATE_CUSTOMER_INFO';

// reducer
const initialState = {
  nameFirst: '',
  nameLast: '',
  emailAddress: '',
  phoneNumber: '',
  password: '',
  streetLine: '',
  isSameMailingAddress: 1,
  mailingStreetLine: '',
  mailingCity: '',
  mailingState: '',
  mailingPostalCode: '',
  additionalInsureds: [],
  interestedParties: [],
  ssnData: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_ENROLLMENT:
      return initialState;
    default:
      return state;
  }
};

// action creators
export const updateCustomerInfo = (payload) => ({
  type: UPDATE_CUSTOMER_INFO,
  payload,
});
