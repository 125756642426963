/* eslint-disable */
import stripObjectKeys from 'utils/stripObjectKeys'

const localStorageSupported = (() => {
  const key = 'lclTest'
  try {
    localStorage.setItem(key, key)
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
})()

export const clearLocalState = key =>
  localStorageSupported && localStorage.removeItem(key)

export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const loadOrExpireState = (key, expiration) => {
  const state = loadState(key)
  if (!state) return undefined
  if (Date.now() - +state.lastUpdated < expiration) {
    return state
  } else {
    // make sure we do an async clear
    setTimeout(() => clearLocalState(key), 0)
    return undefined
  }
}

export const saveState = (state, key, stripKeys) => {
  // strip unwanted state keys if desired
  if (stripKeys) {
    state = stripObjectKeys(state, stripKeys)
  }

  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(key, serializedState)
  } catch (err) {
    // ignore write errors for now
  }
}
