const billingFrequencies = {
  4: {
    name: 'Monthly',
    value: 4,
    abbr: 'mo',
    factor: 1,
    subTitle: 'every month',
  },
  5: {
    name: 'Quarterly',
    value: 5,
    abbr: '4 mos',
    factor: 3,
    subTitle: 'every 3 months',
  },
  10: {
    name: 'Semiannual',
    value: 10,
    abbr: '6 mos',
    factor: 6,
    subTitle: 'every 6 months',
  },
  6: {
    name: 'Annual',
    value: 6,
    abbr: 'yr',
    factor: 12,
    subTitle: 'every 12 months',
  },
};

export default billingFrequencies;
