import { USER_LOGOUT } from 'redux/modules/user';

// action types
export const ADD_REQUEST_ERROR = 'ri/requestErrors/ADD_REQUEST_ERROR';
export const CLEAR_REQUEST_ERRORS = 'ri/requestErrors/CLEAR_REQUEST_ERRORS';

// reducer
export default (state = [], action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_REQUEST_ERROR:
      return [...state, payload];
    case CLEAR_REQUEST_ERRORS:
    case USER_LOGOUT:
      return [];
    default:
      return state;
  }
};

export const addRequestError = (payload) => ({
  type: ADD_REQUEST_ERROR,
  payload,
});
