// action types
const PUSH_REFERRER = 'ri/referrers/PUSH_REFERRER';
const POP_REFERRER = 'ri/referrers/POP_REFERRER';
const CLEAR_REFERRERS = 'ri/referrers/CLEAR_REFERRERS';

// action creators
export const pushReferrer = (payload) => ({
  type: PUSH_REFERRER,
  payload,
});
export const popReferrer = () => ({ type: POP_REFERRER });
export const clearReferrers = () => ({ type: CLEAR_REFERRERS });

// reducer
export default (state = [], action = {}) => {
  switch (action.type) {
    case PUSH_REFERRER:
      return [...state, action.payload];
    case POP_REFERRER:
      return state.slice(0, state.length - 1);
    case CLEAR_REFERRERS:
      return [];
    default:
      return state;
  }
};

// selectors
export const referrerSelector = ({ referrers }) => ({
  referrer: referrers[referrers.length - 1],
});
