/* eslint-disable */
import { CLEAR_ENROLLMENT } from './enrollment'

// action types
const UPDATE_PAYMENT_INFO = 'ri/enrollPaymentInfo/UPDATE_PAYMENT_INFO'

// reducer
const initialState = {
  isSameBillingAddress: 1,
  billingStreetLine: '',
  billingCity: '',
  billingState: '',
  billingPostalCode: '',
  paymentType: 'credit',
  paymentTypeId: '',
  checkAccountTypeId: 3,
  checkNameOnAccount: '',
  checkBankName: '',
  checkRoutingNumber: '',
  checkAccountNumber: '',
  ccNameOnCard: '',
  ccExpDateMonth: '',
  ccExpDateYear: '',
  ccCardNumberMasked: '',
  secureReferenceNumber: '',
  isSubmitLater: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_INFO:
      return action.payload
    case CLEAR_ENROLLMENT:
      return initialState
    default:
      return state
  }
}

// action creators
export const updatePaymentInfo = payload => ({
  type: UPDATE_PAYMENT_INFO,
  payload,
})
