import { css } from '@emotion/core';
import { pxToRems } from '@entrata/portals-common/lib/styled-utils';
import {
  BrownEntrataLightWoff,
  BrownEntrataLightTtf,
  BrownEntrataRegularTtf,
  BrownEntrataRegularWoff,
  BrownEntrataItalicTtf,
  BrownEntrataItalicWoff,
  BrownEntrataBoldTtf,
  BrownEntrataBoldWoff,
  InterRegular,
  InterLight,
  InterBold,
  SoehneBuch,
  SoehneKraftig,
  AlgebraDisplayLight,
} from './src/fonts';

export default ({ primary, fonts, tertiary, text }) => css`
  @font-face {
    font-family: 'BrownEntrataLight';
    src: url(${BrownEntrataLightTtf}) format('truetype'), url(${BrownEntrataLightWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'BrownEntrataRegular';
    src: url(${BrownEntrataRegularTtf}) format('truetype'), url(${BrownEntrataRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'BrownEntrataItalic';
    src: url(${BrownEntrataItalicTtf}) format('truetype'), url(${BrownEntrataItalicWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'BrownEntrataBold';
    src: url(${BrownEntrataBoldTtf}) format('truetype'), url(${BrownEntrataBoldWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'InterRegular';
    src: url(${InterRegular}) format('truetype'), url(${InterRegular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'InterLight';
    src: url(${InterLight}) format('truetype'), url(${InterLight}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'InterBold';
    src: url(${InterBold}) format('truetype'), url(${InterBold}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'SoehneBuch';
    src: url(${SoehneBuch}) format('truetype'), url(${SoehneBuch}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SoehneKraftig';
    src: url(${SoehneKraftig}) format('truetype'), url(${SoehneKraftig}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'AlgebraDisplayLight';
    src: url(${AlgebraDisplayLight}) format('truetype'), url(${AlgebraDisplayLight}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  *:not(svg) {
    box-sizing: border-box;
  }

  html,
  body {
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
  }

  body {
    background: ${tertiary.medium};
    color: ${text.medium};
    letter-spacing: 0.5px;
  }

  body,
  .root,
  .app {
    min-width: 100%;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    opacity: 1;
  }

  h1 {
    font-family: ${fonts.regular};
    font-size: ${pxToRems(32)};
    margin: 0 0 1.25em;
  }

  h2 {
    font-family: ${fonts.regular};
    font-size: ${pxToRems(20)};
    margin: 1.5em 0 1.2em;
  }

  h3 {
    font-family: ${fonts.bold};
    font-size: ${pxToRems(16)};
    margin: 1.25em 0;
  }

  p {
    color: ${text.dark};
    font-family: ${fonts.regular};
    font-size: ${pxToRems(14)};
    line-height: 1.5;
    margin-bottom: 1em;
    margin-top: 1em;
    letter-spacing: 0.7px;
    opacity: 1;
  }

  a {
    color: ${primary.base};
    text-decoration: none;
    opacity: 1;
  }

  a[href^='tel:'] {
    color: ${text.medium};
  }

  label {
    font-size: 14px;
    font-family: ${fonts.normal};
    color: ${text.dark};
  }
  select {
    font-family: ${fonts.soehneBuch};
    color: ${text.dark};
    font-size: 15px;
  }
`;
