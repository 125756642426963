// action types
const SET_GLOBAL_LOADING = 'ri/globalLoading/SET_GLOBAL_LOADING';
const UNSET_GLOBAL_LOADING = 'ri/globalLoading/UNSET_GLOBAL_LOADING';
const SET_GLOBAL_LOADING_WITH_DESCRIPTION = 'ri/globalLoading/SET_GLOBAL_LOADING_WITH_DESCRIPTION';

const defualtState = { loading: false, message: '', description: null };

// reducer
export default (state = defualtState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GLOBAL_LOADING:
      return { loading: true, message: payload };
    case SET_GLOBAL_LOADING_WITH_DESCRIPTION: {
      const { message, description } = payload;
      return { loading: true, message, description };
    }
    case UNSET_GLOBAL_LOADING:
      return defualtState;
    default:
      return state;
  }
};

// action creators
export const setGlobalLoading = (payload) => ({
  type: SET_GLOBAL_LOADING,
  payload,
});

export const unsetGlobalLoading = (payload) => ({
  type: UNSET_GLOBAL_LOADING,
  payload,
});

export const setGlobalLoadingWithDetails = (payload) => ({
  type: SET_GLOBAL_LOADING_WITH_DESCRIPTION,
  payload,
});
